import { User } from "type/model/api";
import { UserRoles } from "type/model/view";

export const hasAccess = (user: User, roles: UserRoles[]) => {
  return (
    (roles.includes(UserRoles.Observer) && user.is_observer) ||
    (roles.includes(UserRoles.Tasker) && user.is_tasker) ||
    (roles.includes(UserRoles.Manager) && user.is_manager)
  );
};
