import { Stack } from "@mui/material";
import { ErrorAlert } from "./ErrorAlert";

export const NoAccess = () => {
  return (
    <Stack sx={{ p: 4 }}>
      <ErrorAlert>You don't have access to this page</ErrorAlert>
    </Stack>
  );
};
