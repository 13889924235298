import { Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import { COLORS } from "style";

interface Props {
  children?: React.ReactNode;
  to: string;
}

export const CustomLink = (props: Props) => {
  return <MuiLink component={Link} sx={{ textDecoration: "none", color: COLORS.Y7K_Blue }} {...props}></MuiLink>;
};
