import { Checkbox, InputAdornment, ListItemText, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { CustomSelect } from "component/CustomSelect";
import { ChevronDownIcon, LayerIcon } from "component/Icons";
import { COLORS } from "style";
import { AssetAssetKindEnum } from "type/model/api";
import { transformEnumValue } from "utility/transformer";

interface Props {
  value: string[];
  onChange(newValue: string[]): void;
}

export const AssetKindSelect = ({ value, onChange }: Props) => {
  const renderValue = () => {
    if (value.length > 0) {
      return (
        <Typography variant="body2">
          Assets{" "}
          <Typography component={"span"} variant="h6">
            {value.length} selected
          </Typography>
        </Typography>
      );
    }
    return <Typography variant="body2">Assets</Typography>;
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    if (Array.isArray(e.target.value) && e.target.value.length > 0) {
      onChange(e.target.value);
    } else {
      onChange([]);
    }
  };

  return (
    <CustomSelect
      sx={{ bgcolor: COLORS.WHITE }}
      multiple
      onChange={handleChange}
      displayEmpty
      error={false}
      size="small"
      variant="outlined"
      value={value}
      renderValue={renderValue}
      IconComponent={ChevronDownIcon}
      startAdornment={
        <InputAdornment position="start">
          <LayerIcon />
        </InputAdornment>
      }
      MenuProps={{
        disableAutoFocusItem: true,
        MenuListProps: { sx: { bgcolor: COLORS.DR_WHITE } },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    >
      {Object.values(AssetAssetKindEnum).map((assetKind) => (
        <MenuItem key={assetKind} value={assetKind}>
          <Checkbox checked={value.includes(assetKind)} />
          <ListItemText primary={<Typography variant="body2">{transformEnumValue(assetKind)}</Typography>} />
        </MenuItem>
      ))}
    </CustomSelect>
  );
};
