import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Button, Stack, Typography } from "@mui/material";
import { MissionWithRelatedCompletionReasonEnum } from "type/model/api";
import { ChevronDownIcon } from "component/Icons";
import { CustomMenu } from "component/CustomMenu";
import { MISSION_COMPLETION_REASON_OPTIONS } from "constant";

interface Props {
  onSelect(value: MissionWithRelatedCompletionReasonEnum): void;
  disabled: boolean
}

export const MarkMissionAs = ({ onSelect, disabled }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: MissionWithRelatedCompletionReasonEnum) => () => {
    onSelect(value);
  };

  return (
    <Stack>
      <Button onClick={handleClick} disabled={disabled} variant="contained" size="large" endIcon={<ChevronDownIcon />}>
        Mark as
      </Button>
      <CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {MISSION_COMPLETION_REASON_OPTIONS.map(({ label, value }) => (
          <MenuItem key={value} onClick={handleMenuItemClick(value)} disableRipple>
            <Typography variant="body2">{label}</Typography>
          </MenuItem>
        ))}
      </CustomMenu>
    </Stack>
  );
};
