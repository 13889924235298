import {
  addDays,
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  parseISO,
  setMilliseconds,
  setSeconds,
} from "date-fns";

export const isBetween = (dateToCheck: Date | number, start: Date | number, end: Date | number) => {
  return isAfter(dateToCheck, start) && isBefore(dateToCheck, end);
};

export const isBetweenTime = (dateToCheck: Date | number, start: Date | number, end: Date | number) => {
  const timeToCheck = format(dateToCheck, "HH:mm");
  const startTime = format(start, "HH:mm");
  const endTime = format(end, "HH:mm");

  if (startTime === endTime) {
    return true;
  }

  if (startTime < endTime) {
    return timeToCheck >= startTime && timeToCheck < endTime;
  }

  return timeToCheck < endTime || timeToCheck >= startTime;
};

export const clearSeconds = (value: Date) => setMilliseconds(setSeconds(value, 0), 0);

export const getTimeDifference = (start: Date | number, end: Date | number) => {
  const baseDate = parseISO("2000-01-01T00:00:00.000Z");
  const startTime = format(start, "HH:mm");
  const endTime = format(end, "HH:mm");

  if (endTime === startTime) {
    return 24;
  }

  const diff =
    endTime > startTime
      ? differenceInMinutes(
          addMinutes(addHours(baseDate, getHours(start)), getMinutes(start)),
          addMinutes(addHours(baseDate, getHours(end)), getMinutes(end))
        )
      : differenceInMinutes(
          addMinutes(addHours(baseDate, getHours(start)), getMinutes(start)),
          addDays(addMinutes(addHours(baseDate, getHours(end)), getMinutes(end)), 1)
        );

  return Math.floor(Math.abs(diff) / 6) / 10;
};

export const getDurationDescription = (durationMinutes: number | null) => {
  if (!durationMinutes) {
    return null;
  }

  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;

  if (hours && minutes) {
    return `${hours}hr ${minutes}min`;
  }

  if (hours) {
    return `${hours}hr`;
  }

  return `${minutes}min`;
};
