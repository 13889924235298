import { Alert, AlertProps } from "@mui/material";
import { COLORS } from "style";

export const ErrorAlert = ({ children, sx }: AlertProps) => {
  return (
    <Alert severity="error" sx={{ border: `1px solid ${COLORS.KHMER_CURRY}`, borderRadius: "6px", ...sx }}>
      {children}
    </Alert>
  );
};
