import { Stack, Typography } from "@mui/material";
import { MissionAssetView } from "type/model/view";

interface Props {
  missionAsset: MissionAssetView | null;
}

export const MissionAssetIdentifier = ({ missionAsset }: Props) => {
  if (!missionAsset) {
    return null;
  }

  return (
    <Stack sx={{ whiteSpace: "nowrap" }}>
      <Typography variant="body2">{missionAsset.name}</Typography>
      {missionAsset.callSign ? <Typography variant="body2">{missionAsset.callSign}</Typography> : null}
      {missionAsset.registration ? <Typography variant="body2">{missionAsset.registration}</Typography> : null}
    </Stack>
  );
};
