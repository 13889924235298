import { QueryClient } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { AssetResponse, Unavailability, UnavailabilityResponse } from "type/model/api";
import { AddressesResponse, RoadAmbulancesResponse } from "type/model/api";
import { Asset } from "type/model/api";
import { HelipadsResponse } from "type/model/api";
import {
  AircraftResponse,
  AirportsResponse,
  ApiV1InvitationUser,
  ApiV1LoginUser,
  ApiV1PasswordUser,
  ApiV1PasswordUser1,
  AssetsResponse,
  CrewIndexResponse,
  Leg,
  LegResponse,
  LegsResponse,
  Mission,
  MissionResponse,
  MissionsResponse,
  TimeZonesResponse,
  User,
  UserResponse,
  UsersResponse,
} from "type/model/api";

const COOKIE_NAME = "authorization";

const AXIOS_CONFIG: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
};

axios.interceptors.request.use(
  (config) => {
    const authToken = Cookies.get(COOKIE_NAME);
    if (authToken) {
      config.headers["Authorization"] = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchEndpoint = async <T>(url: string) => {
  const response = await axios.get<T>(url, AXIOS_CONFIG);
  return response.data;
};

export const fetchCurrentUser = () => {
  return fetchEndpoint<UserResponse>("/users/current");
};

export const fetchRosteredUsers = () => {
  return fetchEndpoint<UsersResponse>("/users?q[is_rostered_eq]=true");
};

export const fetchAssets = () => {
  return fetchEndpoint<AssetsResponse>("/assets");
};

export const fetchUser = (userId: number | string | null) => {
  return fetchEndpoint<UsersResponse>(`/users?q[id_eq]=${userId}`);
};

export const fetchUsers = () => {
  return fetchEndpoint<UsersResponse>(`/users?q[s][]=first_name+asc&q[s][]=last_name+asc`);
};

export const fetchAsset = (assetId: string | number) => {
  return fetchEndpoint<AssetsResponse>(`/assets?q[id_eq]=${assetId}`);
};

export const fetchMissions = () => {
  return fetchEndpoint<MissionsResponse>("/missions");
};

export const fetchMission = (missionId: string | number) => {
  return fetchEndpoint<MissionsResponse>(`/missions?q[id_eq]=${missionId}`);
};

export const fetchTimeZones = () => {
  return fetchEndpoint<TimeZonesResponse>("/time_zones");
};

export const fetchCrew = () => {
  return fetchEndpoint<CrewIndexResponse>("/crew?q[s][]=first_name+asc&q[s][]=last_name+asc&per_page=10000&page=1");
};

export const fetchMissionLegs = (missionId: string | number) => {
  return fetchEndpoint<LegsResponse>(`/legs?q[mission_id_eq]=${missionId}&q[s]=leg_order+asc`);
};

export const fetchAircraft = (aircraftKind: number | string) => {
  return fetchEndpoint<AircraftResponse>(`/aircraft?q[aircraft_kind_eq]=${aircraftKind}`);
};

export const fetchAirports = () => {
  return fetchEndpoint<AirportsResponse>("/airports");
};

export const fetchHelipads = () => {
  return fetchEndpoint<HelipadsResponse>("/helipads");
};

export const fetchAddresses = () => {
  return fetchEndpoint<AddressesResponse>("/addresses");
};

export const fetchRoadAmbulances = () => {
  return fetchEndpoint<RoadAmbulancesResponse>("/road_ambulances");
};

export const createMission = async (input: { mission: Partial<Mission> }) => {
  const response = await axios.post<MissionResponse>("/missions", input, AXIOS_CONFIG);
  return response.data;
};

export const createMissionLeg = async (missionId: string | number, input: { leg: Partial<Leg> }) => {
  const response = await axios.post<LegResponse>(`/missions/${missionId}/legs`, input, AXIOS_CONFIG);
  return response.data;
};

export const createMissionCrew = async (missionId: string | number, input: { mission_crew: { crew_id: number } }) => {
  const response = await axios.post(`/missions/${missionId}/crew`, input, AXIOS_CONFIG);
  return response.data;
};

export const deleteMissionCrew = async (missionId: string | number, crewId: string | number) => {
  const response = await axios.delete(`/missions/${missionId}/crew/${crewId}`, AXIOS_CONFIG);
  return response.data;
};

export const updateMissionLeg = async (
  missionId: string | number,
  legId: string | number,
  input: { leg: Partial<Leg> }
) => {
  const response = await axios.patch<LegResponse>(`/missions/${missionId}/legs/${legId}`, input, AXIOS_CONFIG);
  return response.data;
};

export const updateMission = async (missionId: string | number, input: { mission: Partial<Mission> }) => {
  const response = await axios.patch<MissionResponse>(`/missions/${missionId}`, input, AXIOS_CONFIG);
  return response.data;
};

export const updateAsset = async (assetId: string | number, input: { asset: Partial<Asset> }) => {
  const response = await axios.patch<AssetResponse>(`/assets/${assetId}`, input, AXIOS_CONFIG);
  return response.data;
};

export const deleteMissionLeg = async (missionId: string | number, legId: string | number) => {
  const response = await axios.delete<LegResponse>(`/missions/${missionId}/legs/${legId}`, AXIOS_CONFIG);
  return response.data;
};

export const deleteMission = async (missionId: string | number) => {
  const response = await axios.delete<MissionResponse>(`/missions/${missionId}`, AXIOS_CONFIG);
  return response.data;
};

export const updateUser = async (userId: string | number, input: { user: Partial<User> }) => {
  const response = await axios.patch<LegResponse>(`/users/${userId}`, input, AXIOS_CONFIG);
  return response.data;
};

export const createShiftCrew = async (shiftId: string | number, input: { shift_crew: { crew_id: number } }) => {
  const response = await axios.post(`/shifts/${shiftId}/crew`, input, AXIOS_CONFIG);
  return response.data;
};

export const deleteShiftCrew = async (shiftId: string | number, crewId: string | number) => {
  const response = await axios.delete(`/shifts/${shiftId}/crew/${crewId}`, AXIOS_CONFIG);
  return response.data;
};

export const createAssetUnavailability = async (
  assetId: string | number,
  input: { unavailability: Partial<Unavailability> }
) => {
  const response = await axios.post<UnavailabilityResponse>(`/assets/${assetId}/unavailabilities`, input, AXIOS_CONFIG);
  return response.data;
};

export const updateAssetUnavailability = async (
  assetId: string | number,
  unavailabilityId: string | number,
  input: { unavailability: Partial<Unavailability> }
) => {
  const response = await axios.patch<UnavailabilityResponse>(
    `/assets/${assetId}/unavailabilities/${unavailabilityId}`,
    input,
    AXIOS_CONFIG
  );
  return response.data;
};

export const deleteAssetUnavailability = async (assetId: string | number, unavailabilityId: string | number) => {
  const response = await axios.delete<UnavailabilityResponse>(
    `/assets/${assetId}/unavailabilities/${unavailabilityId}`,
    AXIOS_CONFIG
  );
  return response.data;
};

export const login = async (input: { user: ApiV1LoginUser }) => {
  Cookies.remove(COOKIE_NAME);
  const response = await axios.post<UserResponse>("/login", input, AXIOS_CONFIG);
  Cookies.set(COOKIE_NAME, response.headers["authorization"], { expires: 1 });
  return response.data;
};

export const forgotPassword = async (input: { user: ApiV1PasswordUser1 }) => {
  Cookies.remove(COOKIE_NAME);
  const response = await axios.post("/password", input, AXIOS_CONFIG);
  return response.data;
};

export const changePassword = async (input: { user: ApiV1PasswordUser }) => {
  Cookies.remove(COOKIE_NAME);
  const response = await axios.put("/password", input, AXIOS_CONFIG);
  return response.data;
};

export const updateInvitation = async (input: { user: ApiV1InvitationUser }) => {
  Cookies.remove(COOKIE_NAME);
  const response = await axios.put("/invitation", input, AXIOS_CONFIG);
  Cookies.set(COOKIE_NAME, response.headers["authorization"], { expires: 1 });
  return response.data;
};

export const logout = async () => {
  const response = await axios.delete("/logout", AXIOS_CONFIG);
  Cookies.remove(COOKIE_NAME);
  return response.data;
};

export const queryClient = new QueryClient();
