import { InputAdornment, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FormSelect } from "component/FormSelect";
import { LayerIcon } from "component/Icons";
import { UNKNOWN } from "constant";
import orderBy from "lodash/orderBy";
import { AssetIdentifier, AssetStatus } from "module/common";
import { useMemo } from "react";
import { COLORS } from "style";
import { transformAssetView } from "utility/transformer";
import * as api from "api";
import { AssetViewStatus } from "type/model/view";
import { LoadingIndicator } from "component/LoadingIndicator";
import { ErrorAlert } from "component/ErrorAlert";

interface Props {
  label: string;
  value: string | number | null | undefined;
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: string | undefined;
}

export const AssetSelect = (props: Props) => {
  const assetsQuery = useQuery({ queryKey: ["assets"], queryFn: api.fetchAssets });
  const { assets } = useMemo(() => {
    const assets = orderBy((assetsQuery.data?.data ?? []).map(transformAssetView), (asset) => {
      return `${asset.status === AssetViewStatus.Online ? 0 : 1}  ${asset.dashboardOrder.toString(10).padStart(6)}`;
    });

    return { assets };
  }, [assetsQuery.data]);

  const getAssetName = (assetId: unknown) => {
    if (!assetId) {
      return (
        <Typography variant="body2" color={COLORS.DARK_SOULS}>
          Select an asset for this mission
        </Typography>
      );
    }
    const result = assets.find((asset) => asset.id === assetId);
    return result?.name ?? "";
  };

  if (assetsQuery.isLoading) {
    return <LoadingIndicator size={20} />;
  }

  if (assetsQuery.isError) {
    return <ErrorAlert>Something went wrong!</ErrorAlert>;
  }

  return (
    <FormSelect
      {...props}
      required
      fullWidth
      displayEmpty={true}
      renderValue={getAssetName}
      startAdornment={
        <InputAdornment position="start">
          <LayerIcon />
        </InputAdornment>
      }
      items={assets.map((asset) => ({
        value: asset.id.toString(),
        element: (
          <MenuItem key={asset.id} value={asset.id} sx={{ justifyContent: "space-between" }} autoFocus={false}>
            <AssetIdentifier asset={asset} />
            <AssetStatus asset={asset} />
          </MenuItem>
        ),
        readOnlyText: asset.name || UNKNOWN,
      }))}
    />
  );
};
