import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/system";
import { COLORS } from "style";

interface Props extends TypographyProps {
  children?: React.ReactNode;
}

export const ErrorText = (props: Props) => {
  return <Typography {...props} variant="body2" color={COLORS.KHMER_CURRY} />;
};
