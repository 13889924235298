import { FormControl, SelectProps, Typography } from "@mui/material";

import { COLORS } from "style";
import { ErrorText } from "component/ErrorMessage";
import { CustomInputLabel } from "./CustomInputLabel";
import { NotSet } from "./NotSet";
import { ChevronDownIcon } from "./Icons";
import { CustomSelect } from "./CustomSelect";
import { SelectItem } from "type/view";

interface Props extends Omit<SelectProps, "error" | "label" | "children" | "value"> {
  label?: string | undefined;
  error?: string | undefined;
  items: SelectItem[];
  readOnly?: boolean;
  value: string | number | null | undefined;
}

const renderItem =
  (value: string | number | null, item: SelectItem | null | undefined, placeholder: string | undefined) => () => {
    if (!value) {
      return (
        <Typography variant="body2" color={COLORS.DARK_SOULS}>
          {placeholder ?? ""}
        </Typography>
      );
    }

    if (!item) {
      return (
        <Typography variant="body2" color={COLORS.KHMER_CURRY}>
          Item not found
        </Typography>
      );
    }

    return <Typography variant="body2">{item.readOnlyText}</Typography>;
  };

export const FormSelect = ({
  items,
  label,
  error,
  value = "",
  fullWidth,
  required,
  readOnly,
  placeholder,
  renderValue,
  ...restProps
}: Props) => {
  const renderControl = () => {
    const item = items.find((item) => value && item.value === value.toString());

    if (readOnly) {
      return item ? <Typography variant="body2">{item.readOnlyText}</Typography> : <NotSet />;
    }

    return (
      <CustomSelect
        error={false}
        size="small"
        variant="outlined"
        value={value ?? ""}
        renderValue={renderValue ?? renderItem(value, item, placeholder)}
        IconComponent={ChevronDownIcon}
        MenuProps={{
          disableAutoFocusItem: true,
          MenuListProps: { sx: { bgcolor: COLORS.DR_WHITE } },
        }}
        {...restProps}
      >
        {items.map((item) => item.element)}
      </CustomSelect>
    );
  };

  return (
    <FormControl fullWidth={fullWidth} variant="standard" sx={{ gap: 1 }}>
      {label ? <CustomInputLabel required={required}>{label}</CustomInputLabel> : null}
      {renderControl()}
      {error ? <ErrorText>{error}</ErrorText> : null}
    </FormControl>
  );
};
