import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Alert,
  Stack,
} from "@mui/material";
import { MissionLegView } from "type/model/view";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { useFetcher, useParams, useRouteLoaderData } from "react-router-dom";
import { User } from "type/model/api";
import { DEFAULT_TIME_ZONE } from "constant";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FormTextInput } from "component/FormTextInput";
import { FormDateTimePicker } from "component/FormDateTimePicker";
import { COLORS } from "style";
import { editMissionLegsSchema } from "type/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect } from "react";
import { ErrorAlert } from "component/ErrorAlert";
import { isEqual } from "date-fns";
import { DateDiffBadge } from "./DateDiffBadge";
import { transformEnumValue } from "utility/transformer";
import { enAU } from "date-fns/locale";
// import { getDurationDescription } from "utility/dateTime";

interface Props {
  readOnly: boolean;
  onSelectionChanged(legId: number): void;
  selectedMissionLegIds: number[];
  missionLegs: MissionLegView[];
}

export const EditMissionLegsForm = ({ readOnly, missionLegs, selectedMissionLegIds, onSelectionChanged }: Props) => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const currentUserTimeZone = currentUser.time_zone || DEFAULT_TIME_ZONE;
  const currentUserTimeZoneAbbreviation = format(Date.now(), "zzz", {
    timeZone: currentUserTimeZone,
    locale: enAU,
  });
  const params = useParams();
  const fetcher = useFetcher();

  const handleCheckboxChange = (missionLegId: number) => () => {
    onSelectionChanged(missionLegId);
  };

  const { control, getValues, reset, trigger } = useForm({
    mode: "onBlur",
    resolver: zodResolver(editMissionLegsSchema),
    defaultValues: { missionLegs },
  });

  const { fields } = useFieldArray({
    control,
    name: "missionLegs",
  });

  useEffect(() => {
    if (fetcher.data?.formError) {
      reset({ missionLegs });
    }
  }, [missionLegs, reset, fetcher.data?.formError]);

  const updateField = useCallback(
    async (name: string) => {
      if (!name) {
        return;
      }

      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      const [, rowIndexStr, fieldName] = name.split(".");
      if (!rowIndexStr || !fieldName) {
        return;
      }
      const rowIndex = parseInt(rowIndexStr);

      if (!Number.isSafeInteger(rowIndex) || rowIndex < 0 || rowIndex > missionLegs.length) {
        return;
      }

      const currentLegValues = missionLegs[rowIndex];
      const newLegValues = getValues("missionLegs")[rowIndex];

      let legFieldName: string | null = null;
      let legFieldValue: string | number | null = null;

      switch (fieldName) {
        case "fromText":
          if (currentLegValues.fromText !== newLegValues.fromText) {
            legFieldName = "from_text";
            legFieldValue = newLegValues.fromText;
          }
          break;
        case "toText":
          if (currentLegValues.toText !== newLegValues.toText) {
            legFieldName = "to_text";
            legFieldValue = newLegValues.toText;
          }
          break;
        case "pob":
          if (currentLegValues.pob !== newLegValues.pob) {
            legFieldName = "pob";
            legFieldValue = newLegValues.pob;
          }
          break;
        case "startTime":
          if (!newLegValues.startTime) {
            legFieldName = "entered_start_at";
            legFieldValue = null;
          } else {
            if (!currentLegValues.startTime || !isEqual(currentLegValues.startTime, newLegValues.startTime)) {
              legFieldName = "entered_start_at";
              legFieldValue = zonedTimeToUtc(
                newLegValues.startTime,
                currentUser.time_zone || DEFAULT_TIME_ZONE
              ).toISOString();
            }
          }
          break;
        case "endTime":
          if (!newLegValues.endTime) {
            legFieldName = "entered_end_at";
            legFieldValue = null;
          } else {
            if (!currentLegValues.endTime || !isEqual(currentLegValues.endTime, newLegValues.endTime)) {
              legFieldName = "entered_end_at";
              legFieldValue = zonedTimeToUtc(
                newLegValues.endTime,
                currentUser.time_zone || DEFAULT_TIME_ZONE
              ).toISOString();
            }
          }

          break;
        case "sarTime":
          if (!newLegValues.sarTime) {
            legFieldName = "sar_at";
            legFieldValue = null;
          } else {
            if (!currentLegValues.sarTime || !isEqual(currentLegValues.sarTime, newLegValues.sarTime)) {
              legFieldName = "sar_at";
              legFieldValue = zonedTimeToUtc(
                newLegValues.sarTime,
                currentUser.time_zone || DEFAULT_TIME_ZONE
              ).toISOString();
            }
          }
          break;
      }

      if (!legFieldName) {
        return;
      }

      const formData = new FormData();
      const leg = {
        [legFieldName]: legFieldValue,
      };

      formData.append("action", "update");
      formData.append("legId", missionLegs[rowIndex].id.toString());
      formData.append("leg", JSON.stringify(leg));

      fetcher.submit(formData, {
        action: `/main/dashboard/mission/edit/${params.missionId}/legs`,
        method: "post",
      });
    },
    [params.missionId, fetcher, missionLegs, currentUser.time_zone, getValues, trigger]
  );

  const handleFieldBlur = useCallback(
    (event: React.FocusEvent<HTMLFormElement>) => {
      updateField(event.target.name);
    },
    [updateField]
  );

  const handleDateAccept = useCallback(
    (name: `missionLegs.${number}.startTime` | `missionLegs.${number}.endTime` | `missionLegs.${number}.sarTime`) =>
      () => {
        updateField(name);
      },
    [updateField]
  );

  if (missionLegs.length === 0) {
    return <Alert severity="info">There are no mission legs</Alert>;
  }

  return (
    <Stack component="form" onBlur={!readOnly ? handleFieldBlur : undefined} sx={{ gap: 2 }}>
      <TableContainer>
        <Table sx={{ whiteSpace: "nowrap" }}>
          <TableHead>
            <TableRow>
              {!readOnly ? <TableCell></TableCell> : null}
              <TableCell>Leg</TableCell>
              <TableCell>Leg type</TableCell>
              <TableCell>From</TableCell>
              <TableCell>ETD ({currentUserTimeZoneAbbreviation})</TableCell>
              <TableCell>To</TableCell>
              <TableCell>ETA ({currentUserTimeZoneAbbreviation})</TableCell>
              <TableCell>SAR ({currentUserTimeZoneAbbreviation})</TableCell>
              <TableCell>Stage</TableCell>
              <TableCell>POB</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ bgcolor: COLORS.BRILLIANCE }}>
            {fields.map((field, index) => {
              return (
                <TableRow key={field.id} sx={{ height: 100, verticalAlign: "top" }}>
                  {!readOnly ? (
                    <TableCell component="th" scope="row">
                      <Checkbox
                        sx={{ p: 0, mt: "-2px" }}
                        checked={selectedMissionLegIds.includes(missionLegs[index].id)}
                        onChange={handleCheckboxChange(missionLegs[index].id)}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{transformEnumValue(missionLegs[index].legType)}</TableCell>
                  <TableCell>
                    <Controller
                      name={`missionLegs.${index}.fromText`}
                      control={control}
                      render={({ field: { name, onChange, value, onBlur }, fieldState: { error } }) => (
                        <FormTextInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          readOnly={readOnly}
                          error={error?.message}
                          sx={{ bgcolor: COLORS.WHITE }}
                          InputProps={{ sx: { fontWeight: "bold" } }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`missionLegs.${index}.startTime`}
                      control={control}
                      render={({ field: { name, onChange, value, onBlur }, fieldState: { error } }) => (
                        <DateDiffBadge startDate={missionLegs[0]?.startTime} currentDate={value} readOnly={readOnly}>
                          <FormDateTimePicker
                            name={name}
                            icon={"none"}
                            format="HH:mm"
                            showClear
                            onChange={onChange}
                            onAccept={handleDateAccept(name)}
                            onBlur={onBlur}
                            value={value}
                            readOnly={readOnly}
                            error={error?.message}
                            sx={{ bgcolor: COLORS.WHITE }}
                          />
                        </DateDiffBadge>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`missionLegs.${index}.toText`}
                      control={control}
                      render={({ field: { name, onChange, value, onBlur }, fieldState: { error } }) => (
                        <FormTextInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          readOnly={readOnly}
                          error={error?.message}
                          sx={{ bgcolor: COLORS.WHITE }}
                          InputProps={{ sx: { fontWeight: "bold" } }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`missionLegs.${index}.endTime`}
                      control={control}
                      render={({ field: { name, onChange, value, onBlur }, fieldState: { error } }) => (
                        <DateDiffBadge startDate={missionLegs[0]?.startTime} currentDate={value} readOnly={readOnly}>
                          <FormDateTimePicker
                            name={name}
                            icon="none"
                            format="HH:mm"
                            showClear
                            onChange={onChange}
                            onAccept={handleDateAccept(name)}
                            onBlur={onBlur}
                            value={value}
                            readOnly={readOnly}
                            error={error?.message}
                            sx={{ bgcolor: COLORS.WHITE }}
                          />
                        </DateDiffBadge>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`missionLegs.${index}.sarTime`}
                      control={control}
                      render={({ field: { name, onChange, value, onBlur }, fieldState: { error } }) => (
                        <DateDiffBadge startDate={missionLegs[0]?.startTime} currentDate={value} readOnly={readOnly}>
                          <FormDateTimePicker
                            name={name}
                            icon="none"
                            format="HH:mm"
                            showClear
                            onChange={onChange}
                            onAccept={handleDateAccept(name)}
                            onBlur={onBlur}
                            value={value}
                            readOnly={readOnly}
                            error={error?.message}
                            sx={{ bgcolor: COLORS.WHITE }}
                          />
                        </DateDiffBadge>
                      )}
                    />
                  </TableCell>
                  <TableCell>{transformEnumValue(missionLegs[index].stage)}</TableCell>
                  <TableCell>
                    <Controller
                      name={`missionLegs.${index}.pob`}
                      control={control}
                      render={({ field: { name, onChange, value, onBlur }, fieldState: { error } }) => (
                        <FormTextInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          type="text"
                          readOnly={readOnly}
                          error={error?.message}
                          sx={{ bgcolor: COLORS.WHITE }}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
    </Stack>
  );
};
