import { ActionFunction, useParams, useRouteLoaderData } from "react-router-dom";
import * as api from "api";
import { ErrorAlert } from "component/ErrorAlert";
import { transformAssetView, transformCrewView, transformEnumValue, transformMissionView } from "utility/transformer";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { LoadingIndicator } from "component/LoadingIndicator";
import { EditOtherMissionDetailsForm } from "./EditOtherMissionDetailsForm";
import unionBy from "lodash/unionBy";
import { MissionWithRelatedStatusEnum, MissionWithRelatedTaskingTypeEnum, User } from "type/model/api";
import { CrewView } from "type/model/view";

export const editMissionDetailsAction: ActionFunction = async ({ request, params }) => {
  const formValues = await request.formData();

  const missionLegJSON = formValues.get("mission");
  if (missionLegJSON && typeof missionLegJSON === "string") {
    const { crewToAdd = [], crewToDelete = [], ...mission } = JSON.parse(missionLegJSON);
    try {
      await Promise.all([
        api.updateMission(params.missionId!, { mission }),

        ...(crewToAdd as CrewView[]).map(({ id: crew_id }) =>
          api.createMissionCrew(params.missionId!, { mission_crew: { crew_id } })
        ),

        ...(crewToDelete as CrewView[]).map(({ id: crew_id }) => api.deleteMissionCrew(params.missionId!, crew_id)),
      ]);

      api.queryClient.invalidateQueries({ queryKey: ["mission", params.missionId] });
      return null;
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

export const EditMissionDetails = () => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const params = useParams();
  const missionQuery = useQuery({
    queryKey: ["mission", params.missionId],
    queryFn: () => api.fetchMission(params.missionId ?? ""),
    enabled: !!params.missionId,
    refetchOnWindowFocus: false,
  });

  const assetQuery = useQuery({
    queryKey: ["asset", missionQuery.data?.data?.[0].asset_id],
    queryFn: () => api.fetchAsset(missionQuery.data?.data?.[0].asset_id ?? ""),
    enabled: !!missionQuery.data?.data?.[0].asset_id,
  });

  const allCrewQuery = useQuery({
    queryKey: ["crew"],
    queryFn: api.fetchCrew,
  });

  const { mission, asset, allCrew } = useMemo(() => {
    const mission = missionQuery?.data?.data?.map(transformMissionView)?.[0] ?? null;
    const asset = assetQuery?.data?.data?.map(transformAssetView)?.[0] ?? null;
    const allCrew = unionBy(allCrewQuery.data?.data?.map(transformCrewView) ?? [], mission?.crew ?? [], "id");

    return {
      mission,
      asset,
      allCrew,
    };
  }, [missionQuery?.data?.data, assetQuery?.data?.data, allCrewQuery.data?.data]);

  const readOnly =
    !currentUser.is_tasker ||
    (mission?.status !== MissionWithRelatedStatusEnum.Draft &&
      mission?.status !== MissionWithRelatedStatusEnum.Confirmed);

  if (missionQuery.isLoading || assetQuery.isLoading || allCrewQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (missionQuery.isError || assetQuery.isError || allCrewQuery.isError || !asset || !mission) {
    return <ErrorAlert>Something went wrong! Please try again.</ErrorAlert>;
  }

  switch (mission.taskingType) {
    case MissionWithRelatedTaskingTypeEnum.Other:
      return <EditOtherMissionDetailsForm readOnly={readOnly} mission={mission} asset={asset} allCrew={allCrew} />;
    default:
      return <ErrorAlert>Tasking type {transformEnumValue(mission.taskingType)} is not Implemented!</ErrorAlert>;
  }
};
