import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { DialogActions, DialogContent, Stack } from "@mui/material";
import { CustomDialog, CustomDialogTitle } from "component/CustomDialog";
import { ErrorAlert } from "component/ErrorAlert";
import { FormDateTimePicker } from "component/FormDateTimePicker";
import { FormRadioGroup } from "component/FormRadioGroup";
import { FormTextInput } from "component/FormTextInput";
import { DEFAULT_TIME_ZONE } from "constant";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFetcher, useParams, useRouteLoaderData } from "react-router-dom";
import { User } from "type/model/api";
import { AssetView, UnavailabilityOptions } from "type/model/view";
import { AssetUnavailabilitySchema, assetUnavailabilitySchema } from "type/schema";

const options = [
  { label: "Online", value: UnavailabilityOptions.Online },
  { label: "Offline - planned time", value: UnavailabilityOptions.OfflinePlanned },
  { label: "Offline - unspecified time", value: UnavailabilityOptions.OfflineUnplanned },
];

interface Props {
  asset: AssetView;
  onClose(): void;
}

export const AssetUnavailability = ({ asset, onClose }: Props) => {
  const params = useParams();
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const currentUserTimeZone = currentUser.time_zone || DEFAULT_TIME_ZONE;
  const currentUserTimeZoneAbbreviation = format(Date.now(), "zzz", {
    timeZone: currentUserTimeZone,
    locale: enAU,
  });

  const fetcher = useFetcher();

  const defaultValues = useMemo(() => {
    if (!asset.unavailability) {
      return {
        option: UnavailabilityOptions.Online,
        from: null,
        to: null,
        comments: "",
      };
    }

    if (asset.unavailability.startTime || asset.unavailability.endTime) {
      return {
        option: UnavailabilityOptions.OfflinePlanned,
        from: asset.unavailability.startTime,
        to: asset.unavailability.endTime,
        comments: asset.unavailability.comment,
      };
    }

    return {
      option: UnavailabilityOptions.OfflineUnplanned,
      from: null,
      to: null,
      comments: asset.unavailability.comment,
    };
  }, [asset]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm<AssetUnavailabilitySchema>({
    resolver: zodResolver(assetUnavailabilitySchema),
    mode: "all",
    defaultValues,
  });

  const optionValue = watch("option");

  useEffect(() => {
    if (optionValue !== "offline-planned") {
      setValue("from", null);
      setValue("to", null);
      trigger();
    }

    if (optionValue === "online") {
      setValue("comments", null);
    }
  }, [optionValue, setValue, trigger]);

  const onSubmit = (values: AssetUnavailabilitySchema) => {
    const formData = new FormData();

    if (values.option === UnavailabilityOptions.Online && !asset.unavailability) {
      onClose();
      return;
    }

    if (values.option === UnavailabilityOptions.Online) {
      formData.append("action", "deleteAssetUnavailability");
      formData.append("unavailabilityId", asset.unavailability!.id!.toString());
    } else {
      if (!asset.unavailability) {
        formData.append("action", "createAssetUnavailability");
      } else {
        formData.append("action", "updateAssetUnavailability");
        formData.append("unavailabilityId", asset.unavailability.id!.toString());
      }

      formData.append(
        "unavailability",
        JSON.stringify({
          start_at: values.from ? zonedTimeToUtc(values.from, currentUserTimeZone).toISOString() : null,
          end_at: values.to ? zonedTimeToUtc(values.to, currentUserTimeZone).toISOString() : null,
          comment: values.comments || null,
        })
      );
    }

    fetcher.submit(formData, {
      action: `/main/assets/${params.assetId}`,
      method: "post",
    });
  };

  return (
    <CustomDialog open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <CustomDialogTitle onClose={onClose} title="Asset Availability" />
        <DialogContent dividers sx={{ minWidth: 560 }}>
          <Stack sx={{ gap: 2, alignItems: "flex-start" }}>
            <Controller
              name="option"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormRadioGroup
                  required
                  items={options}
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                  label="Availability"
                />
              )}
            />
            {optionValue === "offline-planned" ? (
              <Stack direction="row" sx={{ gap: 2 }}>
                <Controller
                  name="from"
                  control={control}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <FormDateTimePicker
                      fullWidth
                      showClear
                      icon="calendar"
                      label={`Offline from (${currentUserTimeZoneAbbreviation})`}
                      placeholder="Select a date and time"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={error?.message}
                    />
                  )}
                />
                <Controller
                  name="to"
                  control={control}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <FormDateTimePicker
                      fullWidth
                      showClear
                      icon="calendar"
                      label={`Offline to (${currentUserTimeZoneAbbreviation})`}
                      placeholder="Select a date and time"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={error?.message}
                    />
                  )}
                />
              </Stack>
            ) : null}
            {optionValue !== "online" ? (
              <Controller
                name="comments"
                control={control}
                render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                  <FormTextInput
                    multiline
                    placeholder="Enter your comments here"
                    label="Comments"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    InputProps={{ minRows: 2 }}
                  />
                )}
              />
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ gap: 2 }}>
          {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
          <LoadingButton
            size="large"
            variant="contained"
            type="submit"
            disabled={!isValid}
            loading={fetcher.state === "loading" || fetcher.state === "submitting"}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </CustomDialog>
  );
};
