import { LoadingButton } from "@mui/lab";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { CustomDialog, CustomDialogTitle } from "component/CustomDialog";
import { ErrorAlert } from "component/ErrorAlert";
import { MISSION_COMPLETION_REASON_OPTIONS, UNKNOWN } from "constant";
import { useForm } from "react-hook-form";
import { useFetcher, useParams } from "react-router-dom";

interface Props {
  onClose(): void;
}

export const CompleteMissionConfirmationDialog = ({ onClose }: Props) => {
  const params = useParams();
  const fetcher = useFetcher();
  const { handleSubmit } = useForm();

  const completionReasonLabel =
    MISSION_COMPLETION_REASON_OPTIONS.find(({ value }) => value === params.reason)?.label ?? UNKNOWN;

  const onSubmit = () => {
    const formData = new FormData();

    formData.append("action", "update");
    formData.append("mission", JSON.stringify({ completion_reason: params.reason }));

    fetcher.submit(formData, {
      action: `/main/dashboard/mission/edit/${params.missionId}`,
      method: "post",
    });
  };

  return (
    <CustomDialog open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <CustomDialogTitle onClose={onClose} title="Are you sure?" />
        <DialogContent dividers sx={{ maxWidth: 420 }}>
          <Typography>
            Marking this mission as{" "}
            <Typography component="span" sx={{ fontWeight: "bold" }}>
              {completionReasonLabel}
            </Typography>{" "}
            will remove it from the default view within your organisation's dashboard. You can view it again by changing
            your dashboard filters.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ gap: 2 }}>
          {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
          <LoadingButton
            size="large"
            variant="contained"
            type="submit"
            loading={fetcher.state === "loading" || fetcher.state === "submitting"}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </CustomDialog>
  );
};
