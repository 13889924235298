import { Autocomplete, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { CustomTextField } from "component/CustomTextField";
import { ChevronDownIcon, ClearIcon } from "component/Icons";
import { NotSet } from "component/NotSet";
import { COLORS } from "style";
import { CrewView } from "type/model/view";
import { transformEnumValue } from "utility/transformer";

interface Props {
  onRemove(value: CrewView): void;
  readOnly?: boolean;
  value: CrewView;
  onChange: (value: CrewView) => void;
  allCrew: CrewView[];
  showShift?: boolean;
}
export const CrewMembers = ({ value, onChange, allCrew, readOnly, onRemove, showShift = true }: Props) => {
  const handleRemove = () => onRemove(value);

  const roleCrew = allCrew.filter(({ role }) => role === value.role);

  const getCrewLabel = (crew: CrewView) => `${crew.firstName} ${crew.lastName}`;

  const handleChange = (_: unknown, crew: CrewView) => {
    onChange(crew);
  };

  const renderControl = () => {
    if (readOnly) {
      return getCrewLabel(value);
    }

    return (
      <Autocomplete
        disableClearable
        popupIcon={<ChevronDownIcon />}
        isOptionEqualToValue={(option, value) => option?.id?.toString() === value?.id?.toString()}
        value={value}
        onChange={handleChange}
        size="small"
        options={roleCrew}
        getOptionLabel={getCrewLabel}
        renderInput={(props) => <CustomTextField {...props} />}
        renderOption={(props, option) => (
          <MenuItem {...props}>
            <Typography variant="body2">{getCrewLabel(option)}</Typography>
          </MenuItem>
        )}
      />
    );
  };

  return (
    <Stack direction="row" sx={{ p: 2, border: `1px solid ${COLORS.DISCO_BALL}`, borderRadius: "4px", gap: 2 }}>
      {!readOnly ? (
        <Stack sx={{ justifyContent: "center", ml: -1 }}>
          <IconButton onClick={handleRemove} sx={{ justifySelf: "flex-start" }}>
            <ClearIcon />
          </IconButton>
        </Stack>
      ) : null}
      <Stack sx={{ gap: 1, width: showShift ? "50%" : "100%" }}>
        <Typography variant="body2">{transformEnumValue(value?.role)}</Typography>
        <Stack sx={{ flexGrow: 1, justifyContent: "center" }}>{renderControl()}</Stack>
      </Stack>
      {showShift ? (
        <Stack sx={{ gap: 1, flexGrow: 1 }}>
          <Typography variant="body2">Shift</Typography>
          <Stack sx={{ flexGrow: 1, justifyContent: "center" }}>
            {value?.shifts?.[0] ? (
              <Typography variant="body2" color={value.shifts[0].color || COLORS.TROLLEY_GREY}>
                {value.shifts[0].name}
              </Typography>
            ) : (
              <NotSet label="Not Assigned" />
            )}
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};
