import { Button, Stack, Table, TableBody, TableCell, TableCellProps, TableRow, Typography } from "@mui/material";
import { COLORS } from "style";
import { MissionDetailLabel } from "./MissionDetailLabel";
import {
  AmbulanceIcon,
  AppsIcon,
  ClockIcon,
  Dashboard3Icon,
  LayerIcon,
  SupportIcon,
  TimerIcon,
  UserGroupIcon,
  UserIcon,
} from "component/Icons";
import { AssetView, CrewView, MissionView } from "type/model/view";
import { NotSet } from "component/NotSet";
import { FormSelect } from "component/FormSelect";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FormTextInput } from "component/FormTextInput";
import { Link, Route, Routes, useFetcher, useNavigate, useRouteLoaderData } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { UserAvatar, MissionAssetIdentifier, AddCrewDialog, CrewMembers } from "module/common";
import { ErrorAlert } from "component/ErrorAlert";
import { MissionWithRelatedStatusEnum, User } from "type/model/api";
import { LoadingButton } from "@mui/lab";
import { pathWithSearchParams } from "utility/router";
import { DEFAULT_TIME_ZONE, RISK_SCORE_SELECT_ITEMS } from "constant";
import { transformEnumValue } from "utility/transformer";
import { FormRadioGroup } from "component/FormRadioGroup";
import { FormDateTimePicker } from "component/FormDateTimePicker";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { zodResolver } from "@hookform/resolvers/zod";
import { EditOtherMissionFormProps, editOtherMissionSchema } from "type/schema";

const TableLabelCell = ({ sx, ...restProps }: TableCellProps) => (
  <TableCell sx={{ border: 0, width: "15%", whiteSpace: "nowrap", verticalAlign: "top", ...sx }} {...restProps} />
);

const TableContentCell = ({ sx, ...restProps }: TableCellProps) => (
  <TableCell sx={{ border: 0, verticalAlign: "top", ...sx }} {...restProps} />
);

const TextView = ({ value }: { value: string | null | undefined }) => {
  return value ? (
    <Typography variant="body2" color={COLORS.SHADOW_GARGOYLE}>
      {value}
    </Typography>
  ) : (
    <NotSet />
  );
};

interface Props {
  readOnly: boolean;
  mission: MissionView;
  asset: AssetView;
  allCrew: CrewView[];
}

export const EditOtherMissionDetailsForm = ({ readOnly, mission, asset, allCrew }: Props) => {
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const currentUserTimeZone = currentUser.time_zone || DEFAULT_TIME_ZONE;
  const currentUserTimeZoneAbbreviation = format(Date.now(), "zzz", {
    timeZone: currentUserTimeZone,
    locale: enAU,
  });

  const transformMissionFormProps: (mission: MissionView) => Partial<EditOtherMissionFormProps> = (mission) => {
    return {
      ...mission,
      isMedicalMissionRespondAvailable: mission.isMedicalMissionRespondAvailable ? "true" : "false",
      plannedDepartureTime: mission.plannedDepartureTime ?? undefined,
      estimatedReturnToBaseTime: mission.estimatedReturnToBaseTime ?? undefined,
    };
  };

  const { handleSubmit, control, watch, reset } = useForm<Partial<EditOtherMissionFormProps>>({
    resolver: zodResolver(editOtherMissionSchema),
    mode: "all",
    defaultValues: transformMissionFormProps(mission),
  });

  useEffect(() => {
    reset(transformMissionFormProps(mission));
  }, [mission, reset]);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "crew",
  });

  const allocatedCrew = watch("crew");

  const availableCrew = useMemo(() => {
    return allCrew.filter(({ id }) => !allocatedCrew?.some((crew) => crew.id === id));
  }, [allCrew, allocatedCrew]);

  const makeFormData = (values: Partial<EditOtherMissionFormProps>, status: MissionWithRelatedStatusEnum | null ) : FormData => {
    const formData = new FormData();

    const crewToAdd = values.crew?.filter(({ id }) => !mission.crew.find((c) => c.id === id)) ?? [];
    const crewToDelete = mission.crew.filter((oldCrew) => !values.crew?.find((c) => c.id === oldCrew.id)) ?? [];

    const transformedData = {
      comments: values.comments ?? null,
      aviation_risk: values.riskScore ?? null,
      mru_number: values.mru ?? null,
      able_respond_medical_mission: values.isMedicalMissionRespondAvailable === "true",
      planned_departure_at: values.plannedDepartureTime
        ? zonedTimeToUtc(values.plannedDepartureTime, currentUserTimeZone).toISOString()
        : undefined,
      estimated_return_to_base_at: values.estimatedReturnToBaseTime
        ? zonedTimeToUtc(values.estimatedReturnToBaseTime, currentUserTimeZone).toISOString()
        : undefined,
      recall_time: values.recallTime ?? null,
    };

    const statusData = status ? { status } : {} 

    formData.append("mission", JSON.stringify({ crewToAdd, crewToDelete, ...transformedData, ...statusData }));

    return formData;
  };

  const onSubmit = (values: Partial<EditOtherMissionFormProps>) => {
    const formData = makeFormData(values, null);
    fetcher.submit(formData, {
      action: `/main/dashboard/mission/edit/${mission.id}/details`,
      method: "post",
    });
  };

  const onSubmitMissionConfirm = (values: Partial<EditOtherMissionFormProps>) => {
    const formData = makeFormData(values, MissionWithRelatedStatusEnum.Confirmed);
    fetcher.submit(formData, {
      action: `/main/dashboard/mission/edit/${mission.id}/details`,
      method: "post",
    });
  };

  const handleRemoveCrew = (index: number) => () => {
    remove(index);
  };

  const handleDialogClose = () => {
    navigate(pathWithSearchParams("."));
  };

  const handleCancel = () => {
    navigate(pathWithSearchParams("/main/dashboard"));
  };

  const handleAddCrew = (crew: CrewView | null) => {
    if (crew) {
      append(crew);
    }
    handleDialogClose();
  };

  return (
    <>
      <Stack component="form" sx={{ width: "100%", gap: 2 }} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          sx={{
            flexGrow: 1,
            border: `2px solid ${COLORS.WHITE_SMOKE}`,
            width: "100%",
            borderRadius: "20px",
          }}
        >
          <Table sx={{ border: 0 }}>
            <TableBody>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel Icon={UserIcon} label="Created by" />
                </TableLabelCell>
                <TableContentCell>
                  <UserAvatar userId={mission.createBy} showName />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel Icon={AppsIcon} label="Tasking type" />
                </TableLabelCell>
                <TableContentCell>
                  <TextView value={transformEnumValue(mission.taskingType)} />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel Icon={LayerIcon} label="Asset" />
                </TableLabelCell>
                <TableContentCell>
                  <MissionAssetIdentifier missionAsset={mission.missionAsset} />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel Icon={Dashboard3Icon} label="Risk score" />
                </TableLabelCell>
                <TableContentCell>
                  <Controller
                    name="riskScore"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <FormSelect
                        placeholder="Select a risk score"
                        displayEmpty={true}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        readOnly={readOnly}
                        error={error?.message}
                        sx={{ minWidth: 350 }}
                        items={RISK_SCORE_SELECT_ITEMS}
                      />
                    )}
                  />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel Icon={SupportIcon} label="MRU" />
                </TableLabelCell>
                <TableContentCell>
                  <Controller
                    name="mru"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <FormTextInput
                        placeholder="MRU"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        readOnly={readOnly}
                        error={error?.message}
                        type="text"
                        sx={{ minWidth: 350 }}
                      />
                    )}
                  />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell sx={{ verticalAlign: "center" }}>
                  <MissionDetailLabel Icon={AmbulanceIcon} label="Medical mission response available" />
                </TableLabelCell>
                <TableContentCell>
                  <Controller
                    name="isMedicalMissionRespondAvailable"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <FormRadioGroup
                        fullWidth
                        row
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        readOnly={readOnly}
                        error={error?.message}
                        items={[
                          { label: "Yes", value: "true" },
                          { label: "No", value: "false" },
                        ]}
                      />
                    )}
                  />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel Icon={ClockIcon} label={`Departure time (${currentUserTimeZoneAbbreviation})`} />
                </TableLabelCell>
                <TableContentCell>
                  <Controller
                    name="plannedDepartureTime"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <FormDateTimePicker
                        icon="none"
                        required
                        placeholder="Enter the departing time"
                        sx={{ width: 350 }}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        readOnly={readOnly}
                        error={error?.message}
                      />
                    )}
                  />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel
                    Icon={ClockIcon}
                    label={`Estimate time for return to base (${currentUserTimeZoneAbbreviation})`}
                  />
                </TableLabelCell>
                <TableContentCell>
                  <Controller
                    name="estimatedReturnToBaseTime"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <FormDateTimePicker
                        icon="none"
                        required
                        placeholder="Enter the departing time"
                        sx={{ width: 350 }}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        readOnly={readOnly}
                        error={error?.message}
                      />
                    )}
                  />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel Icon={TimerIcon} label={"Recall time (minutes)"} />
                </TableLabelCell>
                <TableContentCell>
                  <Controller
                    name="recallTime"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <FormTextInput
                        placeholder="Enter the recall time"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        readOnly={readOnly}
                        error={error?.message}
                        type="text"
                        sx={{ minWidth: 350 }}
                      />
                    )}
                  />
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableLabelCell>
                  <MissionDetailLabel Icon={UserGroupIcon} label="Crew" />
                </TableLabelCell>
                <TableContentCell>
                  <Stack sx={{ gap: 2, maxWidth: 700 }}>
                    {fields.map((item, index) => (
                      <Controller
                        key={item.id}
                        name={`crew.${index}`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <CrewMembers
                            value={value}
                            readOnly={readOnly}
                            onChange={onChange}
                            allCrew={allCrew}
                            onRemove={handleRemoveCrew(index)}
                          />
                        )}
                      />
                    ))}
                    {!readOnly ? (
                      <Button
                        variant="text"
                        component={Link}
                        to={pathWithSearchParams("add-crew")}
                        disabled={!availableCrew.length}
                        sx={{ color: COLORS.HAILEY_BLUE, alignSelf: "flex-start", p: 0 }}
                      >
                        + Add crew member
                      </Button>
                    ) : null}
                  </Stack>
                </TableContentCell>
              </TableRow>
              <TableRow>
                <TableContentCell colSpan={2}>
                  <Controller
                    name="comments"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <FormTextInput
                        multiline
                        placeholder="Enter your comments here"
                        label="Comments"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        readOnly={readOnly}
                        error={error?.message}
                        InputProps={{ minRows: 2 }}
                      />
                    )}
                  />
                </TableContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
        <Stack direction="row" sx={{ alignSelf: "flex-end", gap: 2 }}>
          {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}

          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          {!readOnly ? (
            <LoadingButton
              variant="contained"
              type="submit"
              loading={fetcher.state === "loading" || fetcher.state === "submitting"}
            >
              Save changes
            </LoadingButton>
          ) : null}
          {!readOnly && mission.status === MissionWithRelatedStatusEnum.Draft ? (
            <LoadingButton
              variant="contained"
              onClick={handleSubmit(onSubmitMissionConfirm)}
              loading={fetcher.state === "loading" || fetcher.state === "submitting"}
            >
              Confirm mission
            </LoadingButton>
          ) : null}
        </Stack>
      </Stack>
      {!readOnly ? (
        <Routes>
          <Route
            path="add-crew"
            element={<AddCrewDialog allCrew={availableCrew} onClose={handleDialogClose} onConfirm={handleAddCrew} />}
          />
        </Routes>
      ) : null}
    </>
  );
};
