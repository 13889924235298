import { Stack, Typography } from "@mui/material";
import { DEFAULT_TIME_ZONE } from "constant";
import { format, utcToZonedTime } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { useRouteLoaderData } from "react-router-dom";
import { COLORS } from "style";
import { User } from "type/model/api";
import { AssetView, AssetViewStatus } from "type/model/view";

interface Props {
  asset: AssetView;
}
const containerStyle = { px: 1, py: "4px", borderRadius: "4px" };

export const AssetStatus = ({ asset }: Props) => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };

  if (asset.status === AssetViewStatus.Online && asset.unavailability?.startTime) {
    return (
      <Stack sx={{ ...containerStyle, bgcolor: COLORS.TOXIC_ESSENCE }}>
        <Typography variant="subtitle1">{`Online until ${format(
          utcToZonedTime(asset.unavailability.startTime!, currentUser.time_zone || DEFAULT_TIME_ZONE),
          "dd/MM/yyyy - HH:mm zzz",
          {
            timeZone: currentUser.time_zone || DEFAULT_TIME_ZONE,
            locale: enAU,
          }
        )}`}</Typography>
      </Stack>
    );
  }

  if (asset.status === AssetViewStatus.Online) {
    return (
      <Stack sx={{ ...containerStyle, bgcolor: COLORS.TOXIC_ESSENCE }}>
        <Typography variant="subtitle1">Online</Typography>
      </Stack>
    );
  }

  if (asset.status === AssetViewStatus.Offline && !asset.unavailability?.endTime) {
    return (
      <Stack sx={{ ...containerStyle, bgcolor: COLORS.COTTAGECORE_SUNSET }}>
        <Typography variant="subtitle1">Offline</Typography>
      </Stack>
    );
  }

  let unavailabilityComment = asset.unavailability?.comment ? ` - ${asset.unavailability.comment}` : "";
  if (unavailabilityComment.length > 50) {
    unavailabilityComment = unavailabilityComment.slice(0, 50) + "...";
  }

  return (
    <Stack sx={{ ...containerStyle, bgcolor: COLORS.KANSAS_GRAIN }}>
      <Typography variant="subtitle1">{`Offline${unavailabilityComment} until ${format(
        utcToZonedTime(asset.unavailability?.endTime!, currentUser.time_zone || DEFAULT_TIME_ZONE),
        "dd/MM/yyyy - HH:mm zzz",
        {
          timeZone: currentUser.time_zone || DEFAULT_TIME_ZONE,
          locale: enAU,
        }
      )}`}</Typography>
    </Stack>
  );
};
