import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { COLORS } from "style";
import * as api from "api";
import { useQuery } from "@tanstack/react-query";
import { LoadingIndicator } from "component/LoadingIndicator";
import { ErrorAlert } from "component/ErrorAlert";
import { useMemo } from "react";
import { UNKNOWN } from "constant";
import { transformEnumValue } from "utility/transformer";
import { CustomSwitch } from "component/CustomSwitch";
import { ActionFunction, useFetcher } from "react-router-dom";

export const userListAction: ActionFunction = async ({ request, params }) => {
  const formData = await request.formData();
  const userId = formData.get("userId");
  const userJSON = formData.get("user");

  if (userJSON && typeof userJSON === "string" && userId && typeof userId === "string") {
    try {
      await api.updateUser(userId, { user: JSON.parse(userJSON) });
      api.queryClient.invalidateQueries({ queryKey: ["users"] });

      return null;
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

export const UserList = () => {
  const fetcher = useFetcher();
  const usersQuery = useQuery({
    queryKey: ["users"],
    queryFn: api.fetchUsers,
  });

  const users = useMemo(() => {
    return usersQuery.data?.data?.filter((user) => user.is_manager || user.is_observer || user.is_tasker) ?? null;
  }, [usersQuery.data]);

  if (usersQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (usersQuery.isError || !users) {
    return <ErrorAlert>Something went wrong! Please try again.</ErrorAlert>;
  }

  const handleUserActiveChange = (userId: number, isRostered: boolean) => () => {
    const formData = new FormData();
    formData.append("userId", userId.toString());
    formData.append("user", JSON.stringify({ is_rostered: isRostered }));
    fetcher.submit(formData, {
      action: "/main/users",
      method: "post",
    });
  };

  return (
    <Stack sx={{ py: 7, alignItems: "center" }}>
      <Stack sx={{ width: "90%", gap: 4 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography variant="h1" color={COLORS.BLACK_PANTHER}>
            Users
          </Typography>
          <Typography variant="body2">See your active workforce and make changes.</Typography>
        </Stack>
        <TableContainer
          sx={{
            border: `1px solid ${COLORS.DISCO_BALL}`,
            borderRadius: "4px",
            "& th": {
              borderColor: COLORS.DISCO_BALL,
              fontSize: (theme) => theme.typography.subtitle1,
              color: COLORS.DARK_ELF,
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team member</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow
                    key={user.id}
                    sx={{
                      verticalAlign: "top",
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& td, & th": {
                        borderColor: COLORS.DISCO_BALL,
                        fontSize: (theme) => theme.typography.body2.fontSize,
                        color: COLORS.DARK_ELF,
                      },
                    }}
                  >
                    <TableCell>
                      <Stack direction="row" sx={{ gap: 1 }}>
                        <span>{user.first_name || UNKNOWN}</span>
                        {user.last_name ? <span>{user.last_name}</span> : null}
                      </Stack>
                    </TableCell>
                    <TableCell>{transformEnumValue(user.position)}</TableCell>
                    <TableCell>
                      <Stack sx={{ gap: 1 }}>
                        {user.is_manager ? <span>Manager</span> : null}
                        {user.is_tasker ? <span>Tasking</span> : null}
                        {user.is_observer ? <span>Observer</span> : null}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <CustomSwitch
                        checked={user.is_rostered}
                        onChange={handleUserActiveChange(user.id!, !user.is_rostered)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
      </Stack>
    </Stack>
  );
};
