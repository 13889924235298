import { Stack, Typography } from "@mui/material";
import { UNKNOWN } from "constant";
import { COLORS } from "style";
import { CrewView } from "type/model/view";
import { transformEnumValue } from "utility/transformer";

interface Props {
  crew: CrewView[];
}

const getCrewColor = (crew: CrewView) => {
  if (!crew.shifts[0]?.color) {
    return COLORS.TROLLEY_GREY;
  }
  return crew.shifts[0].color;
};

export const Crew = ({ crew }: Props) => {
  return (
    <Stack sx={{ gap: 1 }}>
      {crew?.map((crew) => {
        return (
          <Typography variant="body2" key={crew.id} sx={{ color: getCrewColor(crew) }}>
            {`${crew.firstName || UNKNOWN} ${crew.lastName || UNKNOWN} - ${
              transformEnumValue(crew.role, "upperEach") || UNKNOWN
            }`}
          </Typography>
        );
      })}
    </Stack>
  );
};
