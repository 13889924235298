import { IconButton, InputAdornment } from "@mui/material";
import { FormTextInput } from "component/FormTextInput";
import { EyeIcon, EyeOffIcon, KeyIcon } from "component/Icons";
import { COLORS } from "style";
import { useState } from "react";

interface Props {
  required?: boolean;
  fullWidth?: boolean;
  label?: string | undefined;
  placeholder?: string | undefined;
  autoComplete?: string | undefined;
  error?: string | undefined;
  value: string | number | null | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const PasswordInput = ({
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  autoComplete,
  fullWidth,
  label,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleToggleShowPassword = () => setShowPassword((state) => !state);

  return (
    <FormTextInput
      label={label}
      required
      placeholder={placeholder}
      fullWidth={fullWidth}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      type={showPassword ? "text" : "password"}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <KeyIcon />
          </InputAdornment>
        ),

        endAdornment: (
          <IconButton onClick={handleToggleShowPassword} sx={{ mr: -1 }}>
            {showPassword ? <EyeOffIcon color={COLORS.BLACK} /> : <EyeIcon color={COLORS.BLACK} />}
          </IconButton>
        ),
      }}
    />
  );
};
