import { TextField, styled } from "@mui/material";
import { COLORS } from "style";

export const CustomTextField = styled(TextField)(() => ({
  margin: 0,

  "& .MuiInputBase-input": {
    position: "relative",
    fontSize: 14,

    "&::placeholder": {
      fontSize: 14,
      color: COLORS.DARK_SOULS,
      opacity: 1,
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.DISCO_BALL,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.HAILEY_BLUE,
    },
  },
}));
