import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, Stack, Typography } from "@mui/material";
import { ErrorAlert } from "component/ErrorAlert";
import { FormDateTimePicker } from "component/FormDateTimePicker";
import { FormRadioGroup } from "component/FormRadioGroup";
import { FormSelect } from "component/FormSelect";
import { FormTextInput } from "component/FormTextInput";
import { AlignJustifyIcon, Dashboard3Icon, DoubleArrowUpIcon } from "component/Icons";
import {
  DEFAULT_TIME_ZONE,
  DOCTOR_LOCATION_OPTIONS,
  MEDICAL_TEAM_OPTIONS,
  MEDICAL_TEAM_READY_OPTIONS,
  PLANNED_DEPARTURE_OPTIONS,
  PRIORITY_SELECT_ITEMS,
  RISK_SCORE_SELECT_ITEMS,
  SPECIAL_CONDITION_SELECT_ITEMS,
} from "constant";
import { format } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { Controller, useForm } from "react-hook-form";
import { useFetcher, useRouteLoaderData } from "react-router-dom";
import { COLORS } from "style";
import {
  Mission,
  MissionStatusEnum,
  MissionTaskingTypeEnum,
  MissionWithRelatedMedicalTeamEnum,
  MissionWithRelatedMedicalTeamReadyEnum,
  MissionWithRelatedPlannedDepartureEnum,
  User,
} from "type/model/api";
import { CreateSecondaryMissionFormProps, createSecondaryMissionSchema } from "type/schema";

export const CreateSecondaryMission = () => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const currentUserTimeZone = currentUser.time_zone || DEFAULT_TIME_ZONE;
  const currentUserTimeZoneAbbreviation = format(Date.now(), "zzz", {
    timeZone: currentUserTimeZone,
    locale: enAU,
  });

  const fetcher = useFetcher();
  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
  } = useForm<Partial<CreateSecondaryMissionFormProps>>({
    resolver: zodResolver(createSecondaryMissionSchema),
    defaultValues: {
      medicalTeam: MissionWithRelatedMedicalTeamEnum.FlightNurseOnly,
      medicalTeamReady: MissionWithRelatedMedicalTeamReadyEnum.Immediate,
      isDropOffOnly: "false",
    },
    mode: "all",
  });

  const plannedDepartureValue = watch("plannedDeparture");
  const medicalTeamValue = watch("medicalTeam");

  const onSubmit = (values: Partial<CreateSecondaryMissionFormProps>) => {
    const formData = new FormData();
    const transformedData: Partial<Mission> = {
      status: MissionStatusEnum.Draft,
      tasking_type: MissionTaskingTypeEnum.Secondary,
    };
    formData.append("mission", JSON.stringify(transformedData));

    // fetcher.submit(formData, {
    //   action: "/main/dashboard/mission/create",
    //   method: "post",
    // });
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ gap: 2 }}>
      <Stack
        sx={{
          flexGrow: 1,
          p: 4,
          border: `2px solid ${COLORS.WHITE_SMOKE}`,
          width: "100%",
          borderRadius: "20px",
          borderTop: 0,
          borderStartStartRadius: 0,
          borderStartEndRadius: 0,
        }}
      >
        <Stack sx={{ width: "100%", maxWidth: 720, marginLeft: "10%", gap: 4, alignItems: "flex-start" }}>
          <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
            <Controller
              name="priority"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormSelect
                  label="Priority"
                  required
                  placeholder="Select a priority"
                  displayEmpty={true}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  sx={{ minWidth: 350 }}
                  startAdornment={
                    <InputAdornment position="start">
                      <DoubleArrowUpIcon />
                    </InputAdornment>
                  }
                  items={PRIORITY_SELECT_ITEMS}
                />
              )}
            />
            <Controller
              name="riskScore"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormSelect
                  label="Risk score"
                  placeholder="Select a risk score"
                  displayEmpty={true}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  sx={{ minWidth: 350 }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Dashboard3Icon />
                    </InputAdornment>
                  }
                  items={RISK_SCORE_SELECT_ITEMS}
                />
              )}
            />
          </Stack>
          <Controller
            name="specialCondition"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormSelect
                label="Special conditions"
                placeholder="Select a mission condition"
                displayEmpty={true}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                sx={{ minWidth: 350 }}
                startAdornment={
                  <InputAdornment position="start">
                    <AlignJustifyIcon />
                  </InputAdornment>
                }
                items={SPECIAL_CONDITION_SELECT_ITEMS}
              />
            )}
          />
          <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
            <Controller
              name="referringHospital"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormTextInput
                  required
                  label="Referring hospital"
                  placeholder="Enter the referring hospital"
                  sx={{ minWidth: 350 }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  type={"text"}
                />
              )}
            />
            <Controller
              name="destinationHospital"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormTextInput
                  required
                  label="Destination hospital"
                  placeholder="Enter the destination hospital"
                  sx={{ minWidth: 350 }}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  type={"text"}
                />
              )}
            />
          </Stack>
          <Stack sx={{ gap: 1 }}>
            <Typography variant="h2">Medical team</Typography>
            <Typography>Select the medical team members</Typography>
          </Stack>
          <Controller
            name="medicalTeam"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormRadioGroup
                fullWidth
                row
                label="Medical team"
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                items={MEDICAL_TEAM_OPTIONS}
              />
            )}
          />
          {medicalTeamValue === MissionWithRelatedMedicalTeamEnum.DoctorRequired ? (
            <Controller
              name="doctorLocation"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormRadioGroup
                  fullWidth
                  row
                  label="Doctor location"
                  required
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  items={DOCTOR_LOCATION_OPTIONS}
                />
              )}
            />
          ) : null}
          <Stack sx={{ gap: 1 }}>
            <Typography variant="h2">Logistics</Typography>
            <Typography>Select the relevant mission times</Typography>
          </Stack>
          <Controller
            name="plannedDeparture"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormRadioGroup
                fullWidth
                row
                label="Planned departure"
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                items={PLANNED_DEPARTURE_OPTIONS}
              />
            )}
          />
          {plannedDepartureValue === MissionWithRelatedPlannedDepartureEnum.SpecificTime ? (
            <Controller
              name="departureTime"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormDateTimePicker
                  icon="clock"
                  label={`Departure time (${currentUserTimeZoneAbbreviation})`}
                  required
                  placeholder="Enter the departing time"
                  sx={{ width: 350 }}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={error?.message}
                />
              )}
            />
          ) : null}
          <Controller
            name="medicalTeamReady"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormRadioGroup
                fullWidth
                row
                label="Medical team ready"
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                items={MEDICAL_TEAM_READY_OPTIONS}
              />
            )}
          />
          <Controller
            name="isDropOffOnly"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormRadioGroup
                fullWidth
                row
                label="Drop off only"
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                items={[
                  { label: "Yes", value: "true" },
                  { label: "No", value: "false" },
                ]}
              />
            )}
          />
        </Stack>
      </Stack>

      <Stack direction="row" sx={{ alignItems: "flex-start", justifyContent: "flex-end", gap: 4 }}>
        {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}

        <LoadingButton
          type="submit"
          loading={fetcher.state === "loading" || fetcher.state === "submitting"}
          disabled={!isValid}
          variant="contained"
          sx={{ whiteSpace: "nowrap" }}
        >
          Next
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
