import { styled, Tab, TabProps } from "@mui/material";
import { COLORS } from "style";

export const CustomTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: COLORS.BLACK,
  fontWeight: theme.typography.fontWeightRegular,
  width: 100,
  "&.Mui-selected": {
    color: COLORS.NEWPORT_INDIGO,
    fontWeight: theme.typography.fontWeightBold,
  },
}));
