import * as api from "api";
import { VALID_SEARCH_PARAM_KEYS } from "constant";
import { redirect, LoaderFunction } from "react-router-dom";
import { UserRoles } from "type/model/view";
import { hasAccess } from "utility/user";

export const getCurrentUser = async (request: Request) => {
  try {
    const response = await api.fetchCurrentUser();
    if (!(response.data.is_manager || response.data.is_observer || response.data.is_tasker)) {
      throw Error();
    }

    return response.data;
  } catch {
    api.queryClient.clear();
    throw redirect(`/auth/login?redirect-to=${encodeURIComponent(request.url)}`);
  }
};

export const checkRoleLoader: (roles: UserRoles[]) => LoaderFunction =
  (roles) =>
  async ({ request }) => {
    const currentUser = await getCurrentUser(request);

    if (hasAccess(currentUser, roles)) {
      return null;
    }

    throw redirect("/main/no-access");
  };

export const pathWithSearchParams = (to: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  Array.from(searchParams.keys()).forEach((key) => {
    if (!VALID_SEARCH_PARAM_KEYS.includes(key)) {
      searchParams.delete(key);
    }
  });

  return `${to}?${searchParams.toString()}`;
};
