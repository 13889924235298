import * as api from "api";
import { ActionFunction, useRouteLoaderData, useSearchParams } from "react-router-dom";
import { MissionWithRelatedStatusEnum, User } from "type/model/api";
import { DashboardHeader } from "module/dashboard-header";
import { useQuery } from "@tanstack/react-query";
import { transformAssetView, transformMissionView } from "utility/transformer";
import { useMemo } from "react";
import { DashboardContent } from "./DashboardContent";
import { LoadingIndicator } from "component/LoadingIndicator";
import { SELECTED_ASSET_KINDS_KEY, SHOW_COMPLETED_MISSIONS_KEY } from "constant";

const REFETCH_INTERVAL = 60_000;

const updateAsset = async (formData: FormData) => {
  const assetId = formData.get("assetId");
  const assetJSON = formData.get("asset");

  if (assetId && typeof assetId === "string" && assetJSON && typeof assetJSON === "string") {
    try {
      await api.updateAsset(assetId, { asset: JSON.parse(assetJSON) });
      await api.queryClient.invalidateQueries({ queryKey: ["assets"] });
      return null;
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

export const dashboardLayoutAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const action = formData.get("action");
  switch (action) {
    case "updateAsset":
      return await updateAsset(formData);
    default:
      return null;
  }
};

export const DashboardLayout = () => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const [searchParam] = useSearchParams();

  const timeZonesQuery = useQuery({ queryKey: ["timeZones"], queryFn: api.fetchTimeZones });
  const rosteredUsersQuery = useQuery({
    queryKey: ["rosteredUsers"],
    queryFn: api.fetchRosteredUsers,
    refetchInterval: REFETCH_INTERVAL,
  });
  const assetsQuery = useQuery({ queryKey: ["assets"], queryFn: api.fetchAssets, refetchInterval: REFETCH_INTERVAL });
  const missionsQuery = useQuery({
    queryKey: ["missions"],
    queryFn: api.fetchMissions,
    refetchInterval: REFETCH_INTERVAL,
  });

  const { timeZones, rosteredUsers, assets, missions } = useMemo(() => {
    const selectedAssetKinds = searchParam.get(SELECTED_ASSET_KINDS_KEY)?.split(",") ?? [];
    const showCompletedMissions = searchParam.get(SHOW_COMPLETED_MISSIONS_KEY) === "true" ?? false;
    const showCancelledMissions = searchParam.get(SHOW_COMPLETED_MISSIONS_KEY) === "true" ?? false;

    const timeZones = timeZonesQuery.data?.data ?? [];
    const rosteredUsers = rosteredUsersQuery.data?.data ?? [];
    const assets = (assetsQuery.data?.data ?? [])
      .map(transformAssetView)
      .filter((asset) => !selectedAssetKinds.length || selectedAssetKinds.includes(asset.assetKind as string));
    const missions = (missionsQuery.data?.data ?? [])
      .map(transformMissionView)
      .filter((mission) => showCompletedMissions || mission.status !== MissionWithRelatedStatusEnum.Complete)
      .filter((mission) => showCancelledMissions || mission.status !== MissionWithRelatedStatusEnum.Cancelled);

    return { timeZones, rosteredUsers, assets, missions };
  }, [timeZonesQuery.data, rosteredUsersQuery.data, assetsQuery.data, missionsQuery.data, searchParam]);

  if (timeZonesQuery.isLoading || rosteredUsersQuery.isLoading || assetsQuery.isLoading || missionsQuery.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <DashboardHeader currentUser={currentUser} timeZones={timeZones} users={rosteredUsers} />
      <DashboardContent assets={assets} missions={missions} />
    </>
  );
};
