import { Stack, Typography } from "@mui/material";
import { IconType } from "component/Icons";
import { COLORS } from "style";

interface Props {
  Icon?: IconType;
  label: string;
}

export const MissionDetailLabel = ({ Icon, label }: Props) => {
  return (
    <Stack direction="row" sx={{ alignItems: "center", gap: "12px" }}>
      {Icon ? <Icon width={24} height={24} color={COLORS.WOLFARM} /> : null}
      <Typography variant="h6"> {label}</Typography>
    </Stack>
  );
};
