import { Stack, Typography } from "@mui/material";
import { DEFAULT_TIME_ZONE } from "constant";
import { format, utcToZonedTime } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { COLORS } from "style";

interface Props {
  userTimeZone: string | null | undefined;
  now: Date | number;
}

export const LocalTimeDisplay = ({ userTimeZone, now }: Props) => {
  const userTime = utcToZonedTime(now, userTimeZone || DEFAULT_TIME_ZONE);
  return (
    <Stack
      sx={{
        bgcolor: COLORS.WHITE,
        borderRadius: "10px",
        border: `1px solid ${COLORS.EXTRAORDINARY_ABUNDANCE_OF_TINGE}`,
        py: 1,
        px: 2,
        flexGrow: 1,
        whiteSpace: "nowrap",
      }}
    >
      <Typography variant="subtitle1" color={COLORS.TROLLEY_GREY}>
        {format(userTime, "zzz", {
          timeZone: userTimeZone || DEFAULT_TIME_ZONE,
          locale: enAU,
        })}
      </Typography>
      <Typography variant="body1" fontSize={24}>
        {format(userTime, "MMMM dd EEEE HH:mm")}
      </Typography>
    </Stack>
  );
};
