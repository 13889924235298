import { LoadingButton } from "@mui/lab";
import { InputAdornment, Stack, Typography } from "@mui/material";
import { login } from "api";
import { CustomLink } from "component/CustomLink";
import { FormTextInput } from "component/FormTextInput";
import { EmailIcon } from "component/Icons";
import { Controller, useForm } from "react-hook-form";
import { ActionFunction, redirect, useFetcher, useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoginFormProps, loginSchema } from "type/schema";
import { ErrorText } from "component/ErrorMessage";
import { AxiosError } from "axios";
import { ALL_USER_ROLES } from "constant";
import { hasAccess } from "utility/user";
import { PasswordInput } from "component/PasswordInput";

export const loginAction: ActionFunction = async ({ request }) => {
  try {
    const { redirectTo, ...loginFormValues } = Object.fromEntries(await request.formData());
    const user = await login({ user: loginSchema.parse(loginFormValues) });

    if (!hasAccess(user.data, ALL_USER_ROLES)) {
      return { formError: "User has no roles assigned!" };
    }

    return redirect(redirectTo as string);
  } catch (error) {
    console.error(error);
    if (error instanceof AxiosError && error.response?.status === 401) {
      return { formError: "Invalid email or password" };
    }
    return { formError: "Something went wrong! Please try again" };
  }
};

export const Login = () => {
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<LoginFormProps>({
    resolver: zodResolver(loginSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const redirectTo = searchParams.get("redirect-to")?.toString() ?? "/main/dashboard";

  const fetcher = useFetcher();
  const errors = fetcher.data;

  const onSubmit = ({ email, password }: LoginFormProps) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("redirectTo", redirectTo);

    fetcher.submit(formData, {
      action: "/auth/login",
      method: "post",
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack sx={{ gap: 4 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography variant="h1">Login to Rapid</Typography>
          <Typography variant="body2">Enter your login details to continue</Typography>
        </Stack>
        <Stack sx={{ gap: 2 }}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormTextInput
                label="Email"
                required
                placeholder="Enter your email address"
                fullWidth
                autoComplete="username"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <PasswordInput
                label="Password"
                required
                placeholder="Enter your password"
                fullWidth
                autoComplete="current-password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
              />
            )}
          />

          <CustomLink to="../forgot-password">Forgot your password?</CustomLink>
        </Stack>
        <Stack sx={{ gap: 2 }}>
          <LoadingButton
            type="submit"
            loading={fetcher.state === "loading" || fetcher.state === "submitting"}
            disabled={!isValid}
            variant="contained"
            size="large"
          >
            Login
          </LoadingButton>
          {errors?.formError ? <ErrorText>{errors?.formError}</ErrorText> : null}
        </Stack>
      </Stack>
    </form>
  );
};
