import { FormControl, TextFieldProps, Typography } from "@mui/material";
import { ErrorText } from "component/ErrorMessage";
import { CustomTextField } from "./CustomTextField";
import { CustomInputLabel } from "./CustomInputLabel";
import { NotSet } from "./NotSet";

interface Props extends Omit<TextFieldProps, "error" | "value"> {
  required?: boolean;
  label?: string | undefined;
  error?: string | undefined;
  readOnly?: boolean;
  value: string | number | null | undefined;
}

export const FormTextInput = ({ fullWidth, label, error, required, value, readOnly, ...restProps }: Props) => {
  const renderControl = () => {
    if (readOnly) {
      return value ? <Typography variant="body2">{value}</Typography> : <NotSet />;
    }

    return (
      <CustomTextField
        value={value ?? ""}
        error={false}
        label=""
        variant="outlined"
        margin={label ? "dense" : "none"}
        size="small"
        {...restProps}
      />
    );
  };
  return (
    <FormControl fullWidth={fullWidth} variant="standard" sx={{ gap: 1 }}>
      {label ? <CustomInputLabel required={required}>{label}</CustomInputLabel> : null}
      {renderControl()}
      {error ? <ErrorText>{error}</ErrorText> : null}
    </FormControl>
  );
};
