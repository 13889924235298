import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { THEME } from "./style";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enAU } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from "@rollbar/react";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};

const ErrorBoundaryFallbackUI: any = () => <div>Oops, something went wrong</div>;

export const App = () => {
  const content = (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={THEME}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={enAU}
          localeText={{ todayButtonLabel: "Now" }}
        >
          <CssBaseline />
          <Outlet />
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );

  if (process.env.NODE_ENV !== "development") {
    return (
      <RollbarProvider config={rollbarConfig}>
        <RollbarErrorBoundary fallbackUI={ErrorBoundaryFallbackUI}>{content}</RollbarErrorBoundary>
      </RollbarProvider>
    );
  }

  return content;
};
