import { Alert, Button, Stack } from "@mui/material";
import { COLORS } from "style";
import { AssetsTable } from "module/assets-table";
import { AssetView, MissionView } from "type/model/view";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { User } from "type/model/api";
import { pathWithSearchParams } from "utility/router";
import { ListFilters } from "module/common";

interface Props {
  assets: AssetView[];
  missions: MissionView[];
}

export const DashboardContent = ({ assets, missions }: Props) => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const navigate = useNavigate();

  const handleNewMissionClick = () => {
    navigate(pathWithSearchParams("mission/create/other"));
  };

  return (
    <Stack sx={{ px: 4, py: 2, gap: "12px" }}>
      <Stack sx={{ alignSelf: "flex-end" }}>
        {currentUser.is_tasker ? (
          <Button variant="contained" onClick={handleNewMissionClick}>
            New Mission
          </Button>
        ) : null}
      </Stack>
      <ListFilters displayShowCompletedMissionsFilter />
      {assets.length > 0 ? (
        <Stack sx={{ flexGrow: 1, border: `1px solid ${COLORS.DISCO_BALL}`, borderRadius: "10px", overflow: "hidden" }}>
          <AssetsTable assets={assets} missions={missions} />
        </Stack>
      ) : (
        <Alert severity="info">No assets to show</Alert>
      )}
    </Stack>
  );
};
