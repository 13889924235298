import { Avatar, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import * as api from "api";
import { LoadingIndicator } from "component/LoadingIndicator";
import { useMemo } from "react";
import { stringAvatar } from "utility";

interface Props {
  userId: number | null;
  showName?: boolean;
}

export const UserAvatar = ({ userId, showName }: Props) => {
  const usersQuery = useQuery({
    queryKey: ["user", userId],
    queryFn: () => api.fetchUser(userId),
    enabled: !!userId,
  });

  const user = useMemo(() => {
    return usersQuery.data?.data?.[0] ?? null;
  }, [usersQuery.data]);

  if (!userId || !user) {
    return <Avatar {...stringAvatar(null, null)} />;
  }

  if (usersQuery.isLoading) {
    return <LoadingIndicator size={20} />;
  }

  return (
    <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
      <Avatar {...stringAvatar(user.first_name, user.last_name)} />
      {showName && (user.first_name || user.last_name) ? (
        <Typography>
          {user.first_name} {user.last_name}
        </Typography>
      ) : null}
    </Stack>
  );
};
