import { Button, Stack, Typography, TypographyProps } from "@mui/material";
import { COLORS } from "style";
import * as api from "api";
import { useQuery } from "@tanstack/react-query";
import { LoadingIndicator } from "component/LoadingIndicator";
import { ErrorAlert } from "component/ErrorAlert";
import { useMemo } from "react";
import { transformAssetView, transformCrewView, transformEnumValue } from "utility/transformer";
import { ActionFunction, Link, Route, Routes, redirect, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftIcon } from "component/Icons";
import unionBy from "lodash/unionBy";
import { AssetShifts } from "./AssetShifts";
import { AssetStatus } from "module/common";
import { AssetUnavailability } from "./AssetUnavailability";
import { pathWithSearchParams } from "utility/router";
import { AircraftSelect } from "./AircraftSelect";
import { AssetWithRelatedAssetKindEnum } from "type/model/api";
import { RoadAmbulanceSelect } from "./RoadAmbulanceSelect";
import { AssetView } from "type/model/view";
import { HelipadSelect } from "./HelipadSelect";
import { AirportSelect } from "./AirportSelect";
import { AddressSelect } from "./AddressSelect";
import { AssetConfigurationSelect } from "./AssetConfigurationSelect";

const DetailLabel = (props: TypographyProps) => <Typography variant="body2" color={COLORS.TROLLEY_GREY} {...props} />;

const updateAsset = async (assetId: string, formData: FormData) => {
  const assetJSON = formData.get("asset");

  if (assetJSON && typeof assetJSON === "string") {
    try {
      await api.updateAsset(assetId, { asset: JSON.parse(assetJSON) });
      await Promise.all([
        api.queryClient.invalidateQueries({ queryKey: ["asset", assetId] }),
        api.queryClient.invalidateQueries({ queryKey: ["assets"] }),
      ]);
      return null;
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

const deleteAssetUnavailability = async (assetId: string, formData: FormData) => {
  const unavailabilityId = formData.get("unavailabilityId");

  if (unavailabilityId && typeof unavailabilityId === "string") {
    try {
      await api.deleteAssetUnavailability(assetId, unavailabilityId);
      await Promise.all([
        api.queryClient.invalidateQueries({ queryKey: ["asset", assetId] }),
        api.queryClient.invalidateQueries({ queryKey: ["assets"] }),
      ]);
      return redirect(".");
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

const createAssetUnavailability = async (assetId: string, formData: FormData) => {
  const unavailabilityJSON = formData.get("unavailability");

  if (unavailabilityJSON && typeof unavailabilityJSON === "string") {
    try {
      await api.createAssetUnavailability(assetId, { unavailability: JSON.parse(unavailabilityJSON) });
      await Promise.all([
        api.queryClient.invalidateQueries({ queryKey: ["asset", assetId] }),
        api.queryClient.invalidateQueries({ queryKey: ["assets"] }),
      ]);
      return redirect(".");
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

const updateAssetUnavailability = async (assetId: string, formData: FormData) => {
  const unavailabilityJSON = formData.get("unavailability");
  const unavailabilityId = formData.get("unavailabilityId");

  if (
    unavailabilityJSON &&
    typeof unavailabilityJSON === "string" &&
    unavailabilityId &&
    typeof unavailabilityId === "string"
  ) {
    try {
      await api.updateAssetUnavailability(assetId, unavailabilityId, {
        unavailability: JSON.parse(unavailabilityJSON),
      });
      await Promise.all([
        api.queryClient.invalidateQueries({ queryKey: ["asset", assetId] }),
        api.queryClient.invalidateQueries({ queryKey: ["assets"] }),
      ]);
      return redirect(".");
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

const updateShiftCrew = async (assetId: string, formData: FormData) => {
  const shiftId = formData.get("shiftId");
  const crewJSON = formData.get("crew");

  if (shiftId && typeof shiftId === "string" && crewJSON && typeof crewJSON === "string") {
    const { crewIdToAdd = null, crewIdToDelete = null } = JSON.parse(crewJSON);
    try {
      if (crewIdToAdd) {
        await api.createShiftCrew(shiftId, { shift_crew: { crew_id: crewIdToAdd } });
      }

      if (crewIdToDelete) {
        await api.deleteShiftCrew(shiftId!, crewIdToDelete);
      }

      await Promise.all([
        api.queryClient.invalidateQueries({ queryKey: ["asset", assetId] }),
        api.queryClient.invalidateQueries({ queryKey: ["assets"] }),
      ]);
      return null;
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

export const assetDetailsAction: ActionFunction = async ({ request, params }) => {
  const formData = await request.formData();
  const action = formData.get("action");

  switch (action) {
    case "updateAsset":
      return await updateAsset(params.assetId!, formData);
    case "createAssetUnavailability":
      return await createAssetUnavailability(params.assetId!, formData);
    case "updateAssetUnavailability":
      return await updateAssetUnavailability(params.assetId!, formData);
    case "deleteAssetUnavailability":
      return await deleteAssetUnavailability(params.assetId!, formData);
    case "updateShiftCrew":
      return await updateShiftCrew(params.assetId!, formData);
    default:
      return null;
  }
};

export const AssetDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const assetQuery = useQuery({
    queryKey: ["asset", params.assetId],
    queryFn: () => api.fetchAsset(params.assetId!),
    enabled: !!params.assetId,
  });

  const allCrewQuery = useQuery({
    queryKey: ["crew"],
    queryFn: api.fetchCrew,
  });

  const asset = useMemo(() => {
    return assetQuery.data?.data?.map(transformAssetView)?.[0] ?? null;
  }, [assetQuery.data]);

  const allCrew = useMemo(() => {
    return unionBy(allCrewQuery.data?.data?.map(transformCrewView) ?? [], asset?.crew ?? [], "id");
  }, [allCrewQuery.data, asset]);

  const handleDialogClose = () => {
    navigate(pathWithSearchParams("."));
  };

  const renderAssetIdentificationSelect = (asset: AssetView) => {
    if (asset.assetKind === AssetWithRelatedAssetKindEnum.RoadAmbulance) {
      return <RoadAmbulanceSelect value={asset.roadAmbulanceId} />;
    }
    return <AircraftSelect value={asset.aircraftId} assetKind={asset.assetKind} />;
  };

  const renderHomeBaseSelect = (asset: AssetView) => {
    switch (asset.assetKind) {
      case AssetWithRelatedAssetKindEnum.Helicopter:
        return <Stack sx={{ gap: "4px" }}>
          <HelipadSelect value={asset.homeBaseHelipadId} />
          <AirportSelect value={asset.homeBaseAirportId} />
        </Stack>;
      case AssetWithRelatedAssetKindEnum.Aeroplane:
        return <AirportSelect value={asset.homeBaseAirportId} />;
      case AssetWithRelatedAssetKindEnum.RoadAmbulance:
        return <AddressSelect value={asset.homeBaseAddressId} />;
    }
  };

  if (assetQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (assetQuery.isError || !asset) {
    return <ErrorAlert sx={{ p: 1 }}>Something went wrong! Please try again.</ErrorAlert>;
  }

  return (
    <>
      <Stack sx={{ py: 7, alignItems: "center" }}>
        <Stack sx={{ width: "90%", gap: 2 }}>
          <Stack sx={{ gap: 1, alignItems: "flex-start" }}>
            <Button component={Link} to={pathWithSearchParams("..")} disableRipple sx={{ gap: 1, ml: -1, mb: 5 }}>
              <ArrowLeftIcon />
              <Typography variant="h2" color={COLORS.NEWPORT_INDIGO}>
                Assets
              </Typography>
            </Button>
          </Stack>
          <Stack
            direction="row"
            sx={{
              border: `1px solid ${COLORS.DISCO_BALL}`,
              borderRadius: "4px",
              justifyContent: "space-between",
              color: COLORS.CARBON,
              p: 2,
            }}
          >
            <Stack sx={{ gap: "4px" }}>
              <DetailLabel>Asset</DetailLabel>
              <Typography variant="body2">{asset.name}</Typography>
            </Stack>
            <Stack sx={{ gap: "4px" }}>
              <DetailLabel>Asset type</DetailLabel>
              <Typography variant="body2">{transformEnumValue(asset.assetKind)}</Typography>
            </Stack>
            <Stack sx={{ gap: "4px" }}>
              <DetailLabel>Asset registration / call sign</DetailLabel>
              {renderAssetIdentificationSelect(asset)}
            </Stack>
            <Stack sx={{ gap: "4px" }}>
              <DetailLabel>Configuration</DetailLabel>
              <AssetConfigurationSelect value={asset.assetConfiguration} />
            </Stack>
            <Stack sx={{ gap: "4px", flexGrow: 0.3 }}>
              <DetailLabel>Home base</DetailLabel>
              {renderHomeBaseSelect(asset)}
            </Stack>
            <Stack sx={{ gap: "4px", maxWidth: 400 }}>
              <DetailLabel>Availability</DetailLabel>
              <Button component={Link} to={pathWithSearchParams("unavailability")} variant="outlined">
                <AssetStatus asset={asset} />
              </Button>
            </Stack>
          </Stack>
          <AssetShifts asset={asset} allCrew={allCrew} />
        </Stack>
      </Stack>
      <Routes>
        <Route path="unavailability" element={<AssetUnavailability asset={asset} onClose={handleDialogClose} />} />
      </Routes>
    </>
  );
};
