import { Badge } from "@mui/material";
import { differenceInCalendarDays } from "date-fns";
import { ReactNode } from "react";

interface Props {
  startDate: Date | null | undefined;
  currentDate: Date | null | undefined;
  children: ReactNode;
  readOnly?: boolean;
}
export const DateDiffBadge = ({ startDate, currentDate, readOnly, children }: Props) => {
  if (readOnly) {
    return <>{children}</>;
  }

  let badgeContent = null;
  if (startDate && currentDate) {
    const diff = differenceInCalendarDays(currentDate, startDate);
    badgeContent = diff > 0 ? `+${diff}` : null;
  }

  return (
    <Badge badgeContent={badgeContent} color="secondary">
      {children}
    </Badge>
  );
};
