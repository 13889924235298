import { FormControl, FormControlLabel, Radio, RadioGroup, RadioGroupProps, Typography } from "@mui/material";
import { COLORS } from "style";
import { ErrorText } from "./ErrorMessage";
import { CustomInputLabel } from "./CustomInputLabel";
import { NotSet } from "./NotSet";
import { RadioItem } from "type/view";

interface Props extends RadioGroupProps {
  label?: string | undefined;
  error?: string | undefined;
  required?: boolean;
  fullWidth?: boolean;
  items: RadioItem[];
  readOnly?: boolean;
}

export const FormRadioGroup = ({
  label,
  fullWidth,
  error,
  items,
  value = "",
  readOnly,
  required,
  ...restProps
}: Props) => {
  const renderControl = () => {
    if (readOnly) {
      const label = items.find((item) => item.value === value?.toString())?.label;
      return label ? <Typography variant="body2">{label}</Typography> : <NotSet />;
    }

    return (
      <RadioGroup value={value} {...restProps}>
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={
              <Radio
                size="small"
                sx={{
                  "&.Mui-checked": {
                    color: COLORS.BNEI_BRAK_BAY,
                  },
                }}
              />
            }
            label={item.label}
            disabled={item.disabled}
          />
        ))}
      </RadioGroup>
    );
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <CustomInputLabel required={required}>{label}</CustomInputLabel>
      {renderControl()}
      {error ? <ErrorText>{error}</ErrorText> : null}
    </FormControl>
  );
};
