import { Stack } from "@mui/material";
import { COLORS } from "style";

export const CreateMissionLayoutBottom = () => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        py: 4,
        border: `2px solid ${COLORS.WHITE_SMOKE}`,
        width: "100%",
        borderRadius: "20px",
        borderTop: 0,
        borderStartStartRadius: 0,
        borderStartEndRadius: 0,
      }}
    ></Stack>
  );
};
