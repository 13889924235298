import { Alert, Typography } from "@mui/material";
import * as api from "api";
import { FormProvider, useForm } from "react-hook-form";
import { ActionFunction, useFetcher, useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { SetPasswordFormProps, setPasswordSchema } from "type/schema";
import { ErrorText } from "component/ErrorMessage";
import { useEffect } from "react";
import { CustomLink } from "component/CustomLink";
import { AxiosError } from "axios";
import { SetPasswordForm } from "./SetPasswordForm";

export const resetPasswordAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData();

  const errorMessage = "Invalid reset password link!";
  try {
    const password = formData.get("password");
    const reset_password_token = formData.get("reset_password_token");
    if (
      typeof reset_password_token !== "string" ||
      typeof password !== "string" ||
      !reset_password_token ||
      !password
    ) {
      return { formError: errorMessage };
    }

    await api.changePassword({ user: { password, reset_password_token } });

    return { formSuccess: true };
  } catch (error) {
    console.error(error);
    if (error instanceof AxiosError && error.response?.status === 422) {
      return { formError: errorMessage };
    }

    return { formError: "Something went wrong! Please try again" };
  }
};

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const formProps = useForm<SetPasswordFormProps>({
    resolver: zodResolver(setPasswordSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { reset } = formProps;

  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.data?.formSuccess) {
      reset();
    }
  }, [fetcher.data, reset]);

  const handleSubmit = ({ password }: SetPasswordFormProps) => {
    const formData = new FormData();
    formData.append("password", password);
    formData.append("reset_password_token", searchParams.get("reset_password_token") ?? "");

    fetcher.submit(formData, {
      action: `/auth/reset-password`,
      method: "post",
    });
  };

  return (
    <FormProvider {...formProps}>
      <SetPasswordForm
        heading={
          <>
            <Typography variant="h1">Change your password</Typography>
            <Typography variant="body2">Enter and confirm your new password.</Typography>
          </>
        }
        onSubmit={handleSubmit}
        errorMessage={fetcher.data?.formError ? <ErrorText>{fetcher.data?.formError}</ErrorText> : null}
        successMessage={
          fetcher.data?.formSuccess ? (
            <Alert severity="success">
              Your password has been changed. Please <CustomLink to="/auth/login">login</CustomLink> with your new
              password
            </Alert>
          ) : null
        }
        loading={fetcher.state === "loading" || fetcher.state === "submitting"}
      />
    </FormProvider>
  );
};
