import * as api from "api";
import { Asset, RoadAmbulance } from "type/model/api";
import { AssetDetailAutocomplete } from "./AssetDetailAutocomplete";
import { Identifiable } from "type/model/view";
import { QueryFunction } from "@tanstack/react-query";

interface Props {
  value: number | null;
}

export const RoadAmbulanceSelect = ({ value }: Props) => {
  const queryKey = ["roadAmbulances"];
  const queryFn = api.fetchRoadAmbulances as QueryFunction<{ data: Identifiable[] }>;

  const getLabel = (roadAmbulance: RoadAmbulance) => {
    return `${roadAmbulance.call_sign}`;
  };

  const getUpdatePayload: (selection: Identifiable) => Partial<Asset> = (selection) => {
    return { road_ambulance_id: selection.id };
  };

  return (
    <AssetDetailAutocomplete
      queryKey={queryKey}
      queryFn={queryFn}
      getLabel={getLabel}
      getUpdatePayload={getUpdatePayload}
      value={value}
    />
  );
};
