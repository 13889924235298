import { SwitchProps, Switch, styled } from "@mui/material";
import { COLORS } from "style";

export const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 50,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 2,
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: COLORS.WHITE,
      "& + .MuiSwitch-track": {
        backgroundColor: COLORS.HAILEY_BLUE,
        opacity: 1,
        border: `1px solid ${COLORS.HAILEY_BLUE}`,
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: COLORS.CHRISTMAS_SILVER,
      border: `1px solid ${COLORS.CHRISTMAS_SILVER}`,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    backgroundColor: COLORS.WHITE,
    width: 26,
    height: 26,
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    backgroundColor: COLORS.SPRINGTIME_RAIN,
    opacity: 1,
    border: `1px solid ${COLORS.SPRINGTIME_RAIN}`,
  },
}));
