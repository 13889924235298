import { Stack } from "@mui/material";
import { logout } from "api";
import { ActionFunction, Outlet, redirect, useFetcher, useLoaderData } from "react-router-dom";
import { User } from "type/model/api";
import { MainHeader } from "./MainHeader";

export const mainAction: ActionFunction = async () => {
  await logout();
  return redirect("/auth/login");
};

export const MainLayout = () => {
  const data = useLoaderData() as { currentUser: User };
  const fetcher = useFetcher();

  const handleLogout = () => {
    fetcher.submit(null, {
      action: "/main",
      method: "post",
    });
  };

  return (
    <Stack sx={{ width: "100%", height: "100%", minWidth: 1200 }}>
      <MainHeader currentUser={data.currentUser} onLogout={handleLogout} />
      <Stack sx={{ flexGrow: 1, overflow: "auto" }}>
        <Outlet />
      </Stack>
    </Stack>
  );
};
