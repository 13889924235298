import {
  ActionFunction,
  Link,
  Route,
  Routes,
  redirect,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import * as api from "api";
import { useQuery } from "@tanstack/react-query";
import { LoadingIndicator } from "component/LoadingIndicator";
import { ErrorAlert } from "component/ErrorAlert";
import { useCallback, useEffect, useMemo, useState } from "react";
import { convertMissionLegTimes, transformMissionLeg } from "utility/transformer";
import { AddMissionLegDialog } from "./AddMissionLegDialog";
import { Button, Stack } from "@mui/material";
import { COLORS } from "style";
import { EditMissionLegsForm } from "./EditMissionLegsForm";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";
import { MissionWithRelatedStatusEnum, User } from "type/model/api";
import { pathWithSearchParams } from "utility/router";
import { DEFAULT_TIME_ZONE } from "constant";

const addMissionLeg = async (missionId: string, formData: FormData) => {
  const missionLegJSON = formData.get("leg");
  if (missionLegJSON && typeof missionLegJSON === "string") {
    try {
      await api.createMissionLeg(missionId, { leg: JSON.parse(missionLegJSON) });
      api.queryClient.invalidateQueries({ queryKey: ["missionLegs", missionId] });
      return redirect(pathWithSearchParams("."));
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

const deleteMissionLegs = async (missionId: string, formData: FormData) => {
  const missionLegIdsJSON = formData.get("legIds");
  if (missionLegIdsJSON && typeof missionLegIdsJSON === "string") {
    try {
      const missionLegIds = JSON.parse(missionLegIdsJSON) as number[];
      await Promise.all(missionLegIds.map((legId) => api.deleteMissionLeg(missionId, legId)));
      api.queryClient.invalidateQueries({ queryKey: ["missionLegs", missionId] });
      return redirect(pathWithSearchParams("."));
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

const updateMissionLegs = async (missionId: string, formData: FormData) => {
  const missionLegJSON = formData.get("leg");
  const missionLegId = formData.get("legId");

  if (missionLegJSON && typeof missionLegJSON === "string" && missionLegId && typeof missionLegId === "string") {
    try {
      await api.updateMissionLeg(missionId, missionLegId, { leg: JSON.parse(missionLegJSON) });
      api.queryClient.invalidateQueries({ queryKey: ["missionLegs", missionId] });
      return null;
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

export const editMissionLegAction: ActionFunction = async ({ request, params }) => {
  const formData = await request.formData();
  const action = formData.get("action");

  switch (action) {
    case "create":
      return await addMissionLeg(params.missionId!, formData);
    case "delete":
      return await deleteMissionLegs(params.missionId!, formData);
    case "update":
      return await updateMissionLegs(params.missionId!, formData);
    default:
      return null;
  }
};

export const EditMissionLegs = () => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const params = useParams();
  const navigate = useNavigate();

  const [selectedMissionLegIds, setSelectedMissionLegIds] = useState<number[]>([]);

  const missionLegsQuery = useQuery({
    queryKey: ["missionLegs", params.missionId],
    queryFn: () => api.fetchMissionLegs(params.missionId ?? ""),
    enabled: !!params.missionId,
    refetchOnWindowFocus: false,
  });

  const missionQuery = useQuery({
    queryKey: ["mission", params.missionId],
    queryFn: () => api.fetchMission(params.missionId ?? ""),
    enabled: !!params.missionId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setSelectedMissionLegIds((currentIds) => {
      return currentIds.filter((selectedId) => missionLegsQuery.data?.data?.find(({ id }) => id === selectedId));
    });
  }, [missionLegsQuery.data?.data, setSelectedMissionLegIds]);

  const orderedMissionLegs = useMemo(() => {
    return (missionLegsQuery.data?.data ?? [])
      .map(transformMissionLeg)
      .map(convertMissionLegTimes(currentUser.time_zone || DEFAULT_TIME_ZONE));
  }, [missionLegsQuery.data?.data, currentUser.time_zone]);

  const handleMissionLegSelectionChange = (missionLegId: number) => {
    setSelectedMissionLegIds((currentIds) => {
      return currentIds.includes(missionLegId)
        ? currentIds.filter((id) => id !== missionLegId)
        : currentIds.concat(missionLegId);
    });
  };

  const handleDialogClose = useCallback(() => {
    navigate(pathWithSearchParams("."));
  }, [navigate]);

  const missionStatus = useMemo(() => missionQuery.data?.data?.[0].status, [missionQuery.data]);

  const readOnly =
    !currentUser.is_tasker ||
    (missionStatus !== MissionWithRelatedStatusEnum.Draft && missionStatus !== MissionWithRelatedStatusEnum.Confirmed);

  if (missionLegsQuery.isLoading || missionQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (missionLegsQuery.isError || missionQuery.isError) {
    return <ErrorAlert>Something went wrong! Please try again.</ErrorAlert>;
  }

  return (
    <Stack sx={{ gap: 2 }}>
      {!readOnly ? (
        <Stack
          sx={{
            bgcolor: COLORS.DOCTOR,
            alignItems: "flex-end",
            height: 50,
            justifyContent: "center",
            p: 4,
            borderRadius: "4px",
          }}
        >
          <Button
            variant="text"
            component={Link}
            to={pathWithSearchParams("delete-mission-legs")}
            disabled={!selectedMissionLegIds.length}
            sx={{ color: COLORS.HAILEY_BLUE, p: 0 }}
          >
            Delete mission leg
          </Button>
        </Stack>
      ) : null}
      <EditMissionLegsForm
        readOnly={readOnly}
        key={orderedMissionLegs.length}
        missionLegs={orderedMissionLegs}
        selectedMissionLegIds={selectedMissionLegIds}
        onSelectionChanged={handleMissionLegSelectionChange}
      />
      {!readOnly ? (
        <Button
          variant="text"
          component={Link}
          to={pathWithSearchParams("add-mission-leg")}
          sx={{ color: COLORS.HAILEY_BLUE, alignSelf: "flex-start", p: 0 }}
        >
          + Add mission leg
        </Button>
      ) : null}
      {!readOnly ? (
        <Routes>
          <Route path="add-mission-leg" element={<AddMissionLegDialog onClose={handleDialogClose} />} />
          <Route
            path="delete-mission-legs"
            element={
              <DeleteConfirmationDialog missionLegIdsToDelete={selectedMissionLegIds} onClose={handleDialogClose} />
            }
          />
        </Routes>
      ) : null}
    </Stack>
  );
};
