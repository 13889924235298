import { styled, Select } from "@mui/material";

import { COLORS } from "style";

export const CustomSelect = styled(Select)(() => ({
  "& .MuiSelect-icon": {
    top: "calc(50% - 0.7em)",
  },
  "&.MuiOutlinedInput-root": {
    fontSize: 14,
    "& fieldset": {
      borderColor: COLORS.DISCO_BALL,
    },
    "&:hover fieldset": {
      borderColor: COLORS.TROLLEY_GREY,
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.HAILEY_BLUE,
    },
  },
}));
