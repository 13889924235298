import { Typography } from "@mui/material";
import { COLORS } from "style";

interface Props {
  label?: string;
}

export const NotSet = ({ label = "Not Set" }: Props) => (
  <Typography variant="body2" color={COLORS.DISCO_BALL}>
    [{label}]
  </Typography>
);
