import { FormControl, InputAdornment, Typography } from "@mui/material";
import { ErrorText } from "component/ErrorMessage";
import { DateTimePickerProps, PickersActionBarAction } from "@mui/x-date-pickers";
import { ClockIcon, DateRangeIcon } from "./Icons";
import { CustomInputLabel } from "./CustomInputLabel";
import { format } from "date-fns";
import { NotSet } from "./NotSet";
import { CustomDateTimePicker } from "./CustomDateTimePicker";
import { clearSeconds } from "utility/dateTime";

interface Props extends Omit<DateTimePickerProps<Date>, "error"> {
  name?: string;
  label?: string | undefined;
  error?: string | undefined;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  onBlur?(): void;
  onChange?(value: Date | null): void;
  icon: "clock" | "calendar" | "none";
  showClear?: boolean;
}

export const FormDateTimePicker = ({
  name,
  placeholder,
  fullWidth,
  label,
  onBlur,
  onChange,
  value = null,
  error,
  required,
  readOnly,
  icon,
  showClear,
  ...restProps
}: Props) => {
  const handleChange = (newValue: Date | null) => {
    onChange?.(newValue ? clearSeconds(newValue) : newValue);
  };

  const renderControl = () => {
    let startAdornment;

    const actions: PickersActionBarAction[] = ["today"];
    if (showClear) {
      actions.unshift("clear");
    }

    switch (icon) {
      case "none":
        startAdornment = null;
        break;
      case "clock":
        startAdornment = (
          <InputAdornment position="start">
            <ClockIcon />
          </InputAdornment>
        );
        break;
      case "calendar":
        startAdornment = (
          <InputAdornment position="start">
            <DateRangeIcon />
          </InputAdornment>
        );
        break;
    }

    if (readOnly) {
      return value ? <Typography variant="body2">{format(value, "dd/MM/yy HH:mm")}</Typography> : <NotSet />;
    }

    return (
      <CustomDateTimePicker
        value={value}
        onClose={onBlur}
        onChange={handleChange}
        slotProps={{
          textField: {
            placeholder,
            onBlur,
            size: "small",
            InputProps: {
              error: false,
              name,
              startAdornment,
            },
          },
          actionBar: { actions },
        }}
        {...restProps}
      />
    );
  };

  return (
    <FormControl fullWidth={fullWidth} variant="standard" sx={{ gap: 1 }}>
      {label ? <CustomInputLabel required={required}>{label}</CustomInputLabel> : null}
      {renderControl()}
      {error ? <ErrorText>{error}</ErrorText> : null}
    </FormControl>
  );
};
