import { createTheme } from "@mui/material/styles";

export const COLORS = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  PORPOISE: "#DADADA",
  SPRINGTIME_RAIN: "#EAEDF3",
  CHRISTMAS_SILVER: "#E0E0E0",
  WHITE_SMOKE: "#F5F5F5",
  NEWPORT_INDIGO: "#2D3D69",
  Y7K_Blue: "#1463FD",
  KHMER_CURRY: "#EB5757",
  LYNX_WHITE: "#F7F7F7",
  STEAM: "#DDDDDD",
  EXTRAORDINARY_ABUNDANCE_OF_TINGE: "#E6E6E6",
  TROLLEY_GREY: "#828282",
  HAILEY_BLUE: "#2D74FF",
  DISCO_BALL: "#D4D4D4",
  QUESTION_MARK_BLOCK: "#F2994A",
  LIGHTISH_PURPLE: "#9B51E0",
  NOGHREI_SILVER: "#BDBDBD",
  DOCTOR: "#F8F9FB",
  SHUTTER_BLUE: "#666E7D",
  WOLFARM: "#B6B6B6",
  TOXIC_ESSENCE: "#CEEDBE",
  KANSAS_GRAIN: "#FFE8CC",
  COTTAGECORE_SUNSET: "#FFD9D9",
  CYNICAL_BLACK: "#171717",
  BNEI_BRAK_BAY: "#1D4ED8",
  DARK_SOULS: "#A3A3A3",
  DR_WHITE: "#FAFAFA",
  DARK_ELF: "#3B3E45",
  BRAINSTEM_GREY: "#B4B4B4",
  SHADOW_GARGOYLE: "#686868",
  WILD_CLARY: "#96A6C2",
  INDUSTRIAL_REVOLUTION: "#737373",
  BRILLIANCE: "#FDFDFD",
  BLACK_PANTHER: "#424242",
  CARBON: "#333333",
};

export const THEME = createTheme({
  palette: {
    primary: {
      main: COLORS.NEWPORT_INDIGO,
    },
    secondary: {
      main: COLORS.HAILEY_BLUE,
    },
    action: {
      disabledBackground: COLORS.SPRINGTIME_RAIN,
      disabled: COLORS.WILD_CLARY,
    },
  },
  typography: {
    fontFamily: "-apple-system,BlinkMacSystemFont,sans-serif",
    h1: {
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: 1.25,
    },
    h3: {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: 1.25,
    },
    h6: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: 1.25,
    },

    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        outlined: {
          borderColor: COLORS.DISCO_BALL,
        },
      },
    },
  },
});
