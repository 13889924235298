import { Stack } from "@mui/system";
import { ReactNode } from "react";
import { COLORS } from "style";

interface Props {
  children: ReactNode;
}

export const CustomBadge = ({ children }: Props) => {
  return (
    <Stack
      sx={{
        px: "6px",
        py: "3px",
        bgcolor: COLORS.Y7K_Blue,
        borderRadius: "2px",
        fontSize: 12,
        fontWeight: 500,
        color: COLORS.DR_WHITE,
      }}
    >
      {children}
    </Stack>
  );
};
