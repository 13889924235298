import { LoadingButton } from "@mui/lab";
import { Alert, InputAdornment, Stack, Typography } from "@mui/material";
import * as api from "api";
import { FormTextInput } from "component/FormTextInput";
import { EmailIcon } from "component/Icons";
import { Controller, useForm } from "react-hook-form";
import { ActionFunction, useFetcher } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { ForgotPasswordFormProps, forgotPasswordSchema } from "type/schema";
import { ErrorText } from "component/ErrorMessage";

export const forgotPasswordAction: ActionFunction = async ({ request }) => {
  try {
    const forgotPasswordFormValues = Object.fromEntries(await request.formData());
    await api.forgotPassword({ user: forgotPasswordSchema.parse(forgotPasswordFormValues) });
  } catch (error) {
    console.error(error);
  }
  return {
    formSuccess:
      "Password reset request has been sent. If your email is in our system you will receive a reset password link",
  };
};

export const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<ForgotPasswordFormProps>({
    resolver: zodResolver(forgotPasswordSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const fetcher = useFetcher();

  const onSubmit = ({ email }: ForgotPasswordFormProps) => {
    const formData = new FormData();
    formData.append("email", email);

    fetcher.submit(formData, {
      action: "/auth/forgot-password",
      method: "post",
    });
  };

  const renderFormContent = () => {
    if (fetcher.data?.formSuccess) {
      return <Alert severity="success">{fetcher.data.formSuccess}</Alert>;
    }

    return (
      <>
        <Stack sx={{ height: 80 }}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormTextInput
                label="Email"
                required
                placeholder="Enter your email address"
                fullWidth
                autoComplete="username"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <Stack sx={{ gap: 2 }}>
          <LoadingButton
            type="submit"
            loading={fetcher.state === "loading" || fetcher.state === "submitting"}
            disabled={!isValid}
            variant="contained"
            size="large"
          >
            Send request
          </LoadingButton>
          {fetcher.data?.formError ? <ErrorText>{fetcher.data?.formError}</ErrorText> : null}
        </Stack>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack sx={{ gap: 4 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography variant="h1">Forgot password?</Typography>
          <Typography variant="body2">Enter your associated email address to reset your password</Typography>
        </Stack>

        {renderFormContent()}
      </Stack>
    </form>
  );
};
