import { UNKNOWN } from "constant";

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (firstName: string | null | undefined, lastName: string | null | undefined) => {
  return {
    sx: {
      bgcolor: stringToColor(`${firstName} ${lastName}`),
      fontSize: 14,
    },
    children: `${firstName?.[0] || UNKNOWN}${lastName?.[0] || UNKNOWN}`,
  };
};

export const formatName = (firstName: string | null | undefined, lastName: string | null | undefined) => {
  return `${firstName?.[0] || UNKNOWN} ${lastName || UNKNOWN}`;
};
