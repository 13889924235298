import { Stack } from "@mui/material";
import { useState } from "react";
import { useInterval } from "react-use";
import { COLORS } from "style";
import { TimeZone, User } from "type/model/api";
import { DashboardUsers } from "./DashboardUsers";
import { LocalTimeDisplay } from "./LocalTimeDisplay";
import { TimeZonesDisplay } from "./TimeZonesDisplay";

interface Props {
  currentUser: User;
  timeZones: TimeZone[];
  users: User[];
}

export const DashboardHeader = ({ currentUser, timeZones, users }: Props) => {
  const [now, setNow] = useState(Date.now());
  useInterval(() => {
    setNow(Date.now());
  }, 1000);

  return (
    <Stack
      direction="row"
      sx={{
        bgcolor: COLORS.LYNX_WHITE,
        py: 2,
        px: 4,
        gap: 1,
        borderBottom: `1px solid ${COLORS.STEAM}`,
      }}
    >
      <LocalTimeDisplay userTimeZone={currentUser.time_zone} now={now} />
      <TimeZonesDisplay timeZones={timeZones} now={now} />
      <DashboardUsers users={users} />
    </Stack>
  );
};
