import { Button, Stack, Typography } from "@mui/material";
import { ArrowLeftIcon } from "component/Icons";
import { Link, Outlet } from "react-router-dom";
import { COLORS } from "style";
import { pathWithSearchParams } from "utility/router";

export const MissionLayout = () => {
  return (
    <Stack sx={{ px: 4, py: 5, alignItems: "flex-start" }}>
      <Button
        component={Link}
        to={pathWithSearchParams("/main/dashboard")}
        disableRipple
        sx={{ gap: 1, ml: -1, mb: 3 }}
      >
        <ArrowLeftIcon />
        <Typography variant="h2" color={COLORS.NEWPORT_INDIGO}>
          Dashboard
        </Typography>
      </Button>
      <Outlet />
    </Stack>
  );
};
