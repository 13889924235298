import { Stack, Typography } from "@mui/material";
import { DEFAULT_TIME_ZONE } from "constant";
import { format, utcToZonedTime } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { COLORS } from "style";
import { TimeZone } from "type/model/api";
import orderBy from "lodash/orderBy";

interface Props {
  now: Date | number;
  timeZones: TimeZone[];
}

export const TimeZonesDisplay = ({ timeZones, now }: Props) => {
  if (!timeZones.length) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        bgcolor: COLORS.WHITE,
        borderRadius: "10px",
        border: `1px solid ${COLORS.EXTRAORDINARY_ABUNDANCE_OF_TINGE}`,
        p: 2,
        gap: 7,
        alignItems: "center",
      }}
    >
      {orderBy(
        timeZones.filter((timeZone) => timeZone.position),
        ["position"]
      ).map((timeZone) => {
        const clockTime = utcToZonedTime(now, timeZone.time_zone || DEFAULT_TIME_ZONE);
        return (
          <Stack key={timeZone.id} sx={{ gap: "4px" }}>
            <Typography variant="subtitle1" color={COLORS.TROLLEY_GREY}>
              {format(clockTime, "zzz", {
                timeZone: timeZone.time_zone || DEFAULT_TIME_ZONE,
                locale: enAU,
              })}
            </Typography>
            <Typography variant="h6">{format(clockTime, "HH:mm")}</Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
