import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { CustomDialog, CustomDialogTitle } from "component/CustomDialog";
import { CustomTextField } from "component/CustomTextField";
import { ChevronDownIcon, UserIcon } from "component/Icons";
import { useMemo, useState } from "react";
import { COLORS } from "style";
import { CrewView } from "type/model/view";
import { transformEnumValue } from "utility/transformer";

interface Props {
  onClose(): void;
  allCrew: CrewView[];
  onConfirm(crew: CrewView | null): void;
}

export const AddCrewDialog = ({ allCrew, onClose, onConfirm }: Props) => {
  const [selectedCrew, setSelectedCrew] = useState(allCrew[0]);

  const options = useMemo(() => {
    if (selectedCrew && !allCrew.find((crew) => crew.id === selectedCrew.id)) {
      return allCrew.concat(selectedCrew);
    }

    return allCrew;
  }, [allCrew, selectedCrew]);

  const getCrewLabel = (crew: CrewView) =>
    `${crew.firstName} ${crew.lastName} - ${transformEnumValue(crew.role, "upperEach")}`;

  const renderCrewItem = (crew: CrewView | null) => {
    if (!crew) {
      return null;
    }

    return (
      <Stack direction="row" sx={{ gap: 1 }}>
        <UserIcon color={COLORS.TROLLEY_GREY} />
        <Typography variant="body2">{getCrewLabel(crew)}</Typography>
      </Stack>
    );
  };

  const handleConfirmClick = () => {
    onConfirm(selectedCrew);
  };

  const handleChange = (event: React.SyntheticEvent, crew: CrewView) => {
    setSelectedCrew(crew);
  };

  return (
    <CustomDialog open onClose={onClose}>
      <CustomDialogTitle onClose={onClose} title="Add crew member" />
      <DialogContent dividers sx={{ minWidth: 420 }}>
        <Stack sx={{ gap: 2 }}>
          <Typography variant="body2">Please select a crew member to add to this mission.</Typography>

          {options.length > 0 ? (
            <Autocomplete
              disableClearable
              popupIcon={<ChevronDownIcon />}
              value={selectedCrew}
              onChange={handleChange}
              size="small"
              options={options}
              getOptionLabel={getCrewLabel}
              renderInput={(props) => (
                <CustomTextField
                  {...props}
                  InputProps={{
                    ...props.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <UserIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => <MenuItem {...props}>{renderCrewItem(option)}</MenuItem>}
            />
          ) : (
            <Typography variant="body2" color={COLORS.KHMER_CURRY}>
              No crew member is available!
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="contained" disabled={!selectedCrew} onClick={handleConfirmClick}>
          Confirm
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
