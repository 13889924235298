import { Stack } from "@mui/material";
import { ReactComponent as LogoSvg } from "asset/svg/logo.svg";
import { CustomTab } from "component/CustomTab";
import { CustomTabs } from "component/CustomTabs";
import React, { useMemo } from "react";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "style";
import { User } from "type/model/api";
import { UserDropdown } from "./UserDropdown";
import { ALL_USER_ROLES } from "constant";
import { UserRoles } from "type/model/view";
import { hasAccess } from "utility/user";
import { pathWithSearchParams } from "utility/router";

const tabs = [
  {
    label: "Dashboard",
    to: "/main/dashboard",
    roles: ALL_USER_ROLES,
  },
  {
    label: "Assets",
    to: "/main/assets",
    roles: [UserRoles.Manager],
  },
  {
    label: "Users",
    to: "/main/users",
    roles: [UserRoles.Manager],
  },
];

interface Props {
  currentUser: User;
  onLogout(): void;
}

export const MainHeader = ({ currentUser, onLogout }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedTabIndex = useMemo(
    () =>
      Math.max(
        tabs.findIndex((tab) => matchPath(tab.to + "/*", pathname)),
        0
      ),
    [pathname]
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    navigate(pathWithSearchParams(tabs[newValue].to));
  };

  return (
    <Stack
      direction="row"
      sx={{
        minHeight: 72,
        px: 4,
        borderBottom: `1px solid ${COLORS.CHRISTMAS_SILVER}`,
      }}
    >
      <Stack component={Link} to="/" sx={{ alignSelf: "center", mr: 8 }}>
        <LogoSvg />
      </Stack>
      <CustomTabs value={selectedTabIndex} onChange={handleChange} sx={{ mb: "-1px" }}>
        {tabs
          .filter((tab) => hasAccess(currentUser, tab.roles))
          .map((tab, index) => (
            <CustomTab key={index} label={tab.label} />
          ))}
      </CustomTabs>
      <Stack sx={{ alignSelf: "center", ml: "auto" }}>
        <UserDropdown user={currentUser} onLogout={onLogout} />
      </Stack>
    </Stack>
  );
};
