import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { SetPasswordFormProps } from "type/schema";
import { COLORS } from "style";
import { PasswordInput } from "component/PasswordInput";
import { ReactNode } from "react";

interface Props {
  onSubmit(values: SetPasswordFormProps): void;
  heading: ReactNode;
  errorMessage?: ReactNode | null;
  successMessage?: ReactNode | null;
  loading: boolean;
}

export const SetPasswordForm = ({ onSubmit, heading, errorMessage, successMessage, loading }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useFormContext<SetPasswordFormProps>();

  const renderFormContent = () => {
    if (successMessage) {
      return successMessage;
    }

    return (
      <>
        <Stack sx={{ gap: 2, height: 190 }}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <PasswordInput
                label="Password"
                required
                placeholder="Enter your new password"
                fullWidth
                autoComplete="new-password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <PasswordInput
                label="Confirm password"
                required
                placeholder="Confirm your new password"
                fullWidth
                autoComplete="new-password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
              />
            )}
          />
        </Stack>

        <Stack sx={{ gap: 2 }}>
          <LoadingButton type="submit" loading={loading} disabled={!isValid} variant="contained" size="large">
            Set password
          </LoadingButton>
          {errorMessage}
        </Stack>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack sx={{ gap: 4 }}>
        <Stack sx={{ gap: 2 }}>
          {heading}
          <Typography variant="subtitle1" color={COLORS.TROLLEY_GREY}>
            Minimum 12 characters, must include one upper case, one lower case, one number, and one punctuation mark.
          </Typography>
        </Stack>

        {renderFormContent()}
      </Stack>
    </form>
  );
};
