import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, Stack, Typography } from "@mui/material";
import { ErrorAlert } from "component/ErrorAlert";
import { FormDateTimePicker } from "component/FormDateTimePicker";
import { FormRadioGroup } from "component/FormRadioGroup";
import { FormTextInput } from "component/FormTextInput";
import { EditIcon, TimerIcon } from "component/Icons";
import { DEFAULT_TIME_ZONE } from "constant";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { AssetSelect } from "module/common";
import { Controller, useForm } from "react-hook-form";
import { ActionFunction, redirect, useFetcher, useRouteLoaderData } from "react-router-dom";
import { COLORS } from "style";
import { Mission, MissionPlannedDepartureEnum, MissionStatusEnum, MissionTaskingTypeEnum, User } from "type/model/api";
import { CreateOtherMissionFormProps, createOtherMissionSchema } from "type/schema";
import * as api from "api";
import { pathWithSearchParams } from "utility/router";

export const createOtherMissionAction: ActionFunction = async ({ request }) => {
  const formValues = await request.formData();
  const missionValues = formValues.get("mission");
  if (missionValues && typeof missionValues === "string") {
    try {
      await api.createMission({ mission: JSON.parse(missionValues) });
      return redirect(pathWithSearchParams("/main/dashboard"));
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

export const CreateOtherMission = () => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const currentUserTimeZone = currentUser.time_zone || DEFAULT_TIME_ZONE;
  const currentUserTimeZoneAbbreviation = format(Date.now(), "zzz", {
    timeZone: currentUserTimeZone,
    locale: enAU,
  });

  const fetcher = useFetcher();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<CreateOtherMissionFormProps>({
    resolver: zodResolver(createOtherMissionSchema),
    mode: "all",
  });

  const renderFormContent = () => {
    return (
      <Stack sx={{ width: "100%", maxWidth: 720, marginLeft: "10%", gap: 4, alignItems: "flex-start" }}>
        <Controller
          name="asset"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <AssetSelect
              label="Asset selection"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />

        <Controller
          name="comments"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <FormTextInput
              label="Comments"
              placeholder="Enter comments"
              fullWidth
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              type={"text"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EditIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Stack sx={{ gap: 1 }}>
          <Typography variant="h2">Asset crew</Typography>
          <Typography>Select the asset crew details</Typography>
        </Stack>
        <Controller
          name="medicalResponse"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <FormRadioGroup
              fullWidth
              row
              label="Medical mission response available"
              required
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              items={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
            />
          )}
        />
        <Controller
          name="departureTime"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <FormDateTimePicker
              icon="clock"
              label={`Departure time (${currentUserTimeZoneAbbreviation})`}
              required
              placeholder="Enter the departing time"
              sx={{ width: 350 }}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={error?.message}
            />
          )}
        />
        <Stack sx={{ gap: 1 }}>
          <Typography variant="h2">Logistics</Typography>
          <Typography>Select the relevant mission times</Typography>
        </Stack>
        <Controller
          name="baseEta"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <FormDateTimePicker
              icon="clock"
              label={`Estimate time for return to base (${currentUserTimeZoneAbbreviation})`}
              required
              placeholder="Enter estimated return to base"
              sx={{ width: 350 }}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={error?.message}
            />
          )}
        />
        <Controller
          name="recallTime"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
            <FormTextInput
              label="Recall time (minutes)"
              placeholder="Enter the recall time"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              type="text"
              sx={{ minWidth: 350 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TimerIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Stack>
    );
  };

  const onSubmit = (values: CreateOtherMissionFormProps) => {
    const formData = new FormData();
    const transformedData: Partial<Mission> = {
      status: MissionStatusEnum.Draft,
      tasking_type: MissionTaskingTypeEnum.Other,
      asset_id: values.asset,
      comments: values.comments,
      able_respond_medical_mission: values.medicalResponse,
      planned_departure: MissionPlannedDepartureEnum.SpecificTime,
      planned_departure_at: zonedTimeToUtc(values.departureTime, currentUserTimeZone).toISOString(),
      estimated_return_to_base_at: zonedTimeToUtc(values.baseEta, currentUserTimeZone).toISOString(),
      recall_time: values.recallTime,
    };
    formData.append("mission", JSON.stringify(transformedData));

    fetcher.submit(formData, {
      action: "/main/dashboard/mission/create/other",
      method: "post",
    });
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ gap: 2 }}>
      <Stack
        sx={{
          flexGrow: 1,
          p: 4,
          border: `2px solid ${COLORS.WHITE_SMOKE}`,
          width: "100%",
          borderRadius: "20px",
          borderTop: 0,
          borderStartStartRadius: 0,
          borderStartEndRadius: 0,
        }}
      >
        {renderFormContent()}
      </Stack>

      <Stack direction="row" sx={{ alignItems: "flex-start", justifyContent: "flex-end", gap: 4 }}>
        {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}

        <LoadingButton
          type="submit"
          loading={fetcher.state === "loading" || fetcher.state === "submitting"}
          disabled={!isValid}
          variant="contained"
          sx={{ whiteSpace: "nowrap" }}
        >
          Save Draft
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
