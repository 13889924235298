import { SelectChangeEvent } from "@mui/material";
import { AssetWithRelatedAssetConfigurationEnum } from "type/model/api";
import { useFetcher, useParams } from "react-router-dom";
import { FormSelect } from "component/FormSelect";
import { ASSET_CONFIGURATION_SELECT_ITEMS } from "constant";

interface Props {
  value: AssetWithRelatedAssetConfigurationEnum | null;
}

export const AssetConfigurationSelect = ({ value }: Props) => {
  const params = useParams();
  const fetcher = useFetcher();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const formData = new FormData();
    formData.append("action", "updateAsset");
    formData.append("asset", JSON.stringify({ asset_configuration: event.target.value }));

    fetcher.submit(formData, {
      action: `/main/assets/${params.assetId}`,
      method: "post",
    });
  };

  return (
    <FormSelect
      sx={{ minWidth: 200 }}
      disabled={fetcher.state === "loading" || fetcher.state === "submitting"}
      onChange={handleChange}
      placeholder="Select a configuration"
      displayEmpty={true}
      value={value}
      items={ASSET_CONFIGURATION_SELECT_ITEMS}
      error={fetcher.data?.formError}
    />
  );
};
