import { styled, Tabs } from "@mui/material";
import { COLORS } from "style";

export const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: COLORS.NEWPORT_INDIGO,
    height: 3,
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
  },
});
