import { Autocomplete, MenuItem, Typography } from "@mui/material";
import { QueryFunction, QueryKey, useQuery } from "@tanstack/react-query";
import { CustomTextField } from "component/CustomTextField";
import { ChevronDownIcon } from "component/Icons";
import { ErrorAlert } from "component/ErrorAlert";
import { LoadingIndicator } from "component/LoadingIndicator";
import { Asset } from "type/model/api";
import { useFetcher, useParams } from "react-router-dom";
import { ErrorText } from "component/ErrorMessage";
import { Identifiable } from "type/model/view";

interface Props {
  placeholder?: string;
  value: number | null;
  getUpdatePayload(item: Identifiable | null): Partial<Asset>;
  queryKey: QueryKey;
  queryFn: QueryFunction<{ data: Identifiable[] }>;
  clearFields?: Object;
  getLabel(item: object): string;
}

export const AssetDetailAutocomplete = ({
  value,
  queryKey,
  queryFn,
  clearFields,
  getUpdatePayload,
  getLabel,
  placeholder,
}: Props) => {
  const params = useParams();
  const fetcher = useFetcher();

  const query = useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  const handleChange = (_: unknown, selection: Identifiable | null) => {
    const formData = new FormData();
    var payload = { ...getUpdatePayload(selection), ...clearFields };
    formData.append("action", "updateAsset");
    formData.append("asset", JSON.stringify(payload));

    fetcher.submit(formData, {
      action: `/main/assets/${params.assetId}`,
      method: "post",
    });
  };

  const selectedItem = query.data?.data.find(({ id }) => id === value);

  if (query.isLoading) {
    return <LoadingIndicator size={20} />;
  }
  if (query.isError) {
    return <ErrorAlert>Something went wrong!</ErrorAlert>;
  }

  return (
    <>
      <Autocomplete<Identifiable, false, true, false>
        disabled={fetcher.state === "loading" || fetcher.state === "submitting"}
        disableClearable
        popupIcon={<ChevronDownIcon />}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        value={selectedItem}
        onChange={handleChange}
        size="small"
        options={query.data.data}
        getOptionLabel={getLabel}
        renderInput={(props) => <CustomTextField {...props} placeholder={placeholder} />}
        renderOption={(props, option) => (
          <MenuItem {...props}>
            <Typography variant="body2">{getLabel(option)}</Typography>
          </MenuItem>
        )}
      />
      {fetcher.data?.formError ? <ErrorText>{fetcher.data?.formError}</ErrorText> : null}
    </>
  );
};
