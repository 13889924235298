import { FormTextInput } from "component/FormTextInput";
import { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";
import { AssetView } from "type/model/view";

interface Props {
  asset: AssetView;
  readOnly: boolean;
}

export const AssetComment = ({ asset, readOnly }: Props) => {
  const fetcher = useFetcher();
  const [comment, setComment] = useState<string | null>(null);

  useEffect(() => {
    setComment(asset.comment);
  }, [asset]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setComment(e.target.value);
  };

  const handleBlur = () => {
    if (asset.comment !== comment) {
      const formData = new FormData();
      formData.append("action", "updateAsset");
      formData.append("assetId", asset.id.toString());
      formData.append("asset", JSON.stringify({ comment }));
      fetcher.submit(formData, {
        action: "/main/dashboard",
        method: "post",
      });
    }
  };

  return (
    <FormTextInput
      fullWidth
      disabled={fetcher.state === "loading" || fetcher.state === "submitting"}
      readOnly={readOnly}
      multiline
      onChange={handleChange}
      onBlur={handleBlur}
      value={comment}
      InputProps={{ sx: { mt: 0 } }}
      minRows={3}
      maxRows={5}
      error={fetcher.data?.formError}
    />
  );
};
