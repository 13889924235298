import { MenuItem, Typography } from "@mui/material";
import { SelectItem } from "type/view";

export const transformSelectItems: (items: { label: string; value: string }[]) => SelectItem[] = (items) =>
  items.map((item) => ({
    value: item.value,
    element: (
      <MenuItem key={item.value} value={item.value} autoFocus={false}>
        <Typography variant="body2">{item.label}</Typography>
      </MenuItem>
    ),
    readOnlyText: item.label,
  }));
