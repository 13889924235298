import * as api from "api";
import { Address, Asset } from "type/model/api";
import { AssetDetailAutocomplete } from "./AssetDetailAutocomplete";
import { Identifiable } from "type/model/view";
import { QueryFunction } from "@tanstack/react-query";

interface Props {
  value: number | null;
}

export const AddressSelect = ({ value }: Props) => {
  const queryKey = ["addresses"];
  const queryFn = api.fetchAddresses as QueryFunction<{ data: Identifiable[] }>;

  const getLabel = (address: Address) => {
    return `${address.name}`;
  };

  const getUpdatePayload = (selection: Identifiable) => {
    return { home_base_address_id: selection.id } as Partial<Asset>;
  };

  return (
    <AssetDetailAutocomplete
      placeholder="Select an address"
      queryKey={queryKey}
      queryFn={queryFn}
      getLabel={getLabel}
      getUpdatePayload={getUpdatePayload}
      value={value}
    />
  );
};
