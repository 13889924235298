import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Button, Stack } from "@mui/material";
import { User } from "type/model/api";
import { stringAvatar } from "utility";
import { COLORS } from "style";
import { ChevronDownIcon, LogoutIcon } from "component/Icons";
import { CustomMenu } from "component/CustomMenu";

interface Props {
  user: User;
  onLogout(): void;
}

export const UserDropdown = ({ user, onLogout }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <Button disableRipple onClick={handleClick} sx={{ gap: 1 }}>
        <Avatar {...stringAvatar(user.first_name, user.last_name)} />
        <ChevronDownIcon />
      </Button>
      <CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={onLogout} disableRipple sx={{ color: COLORS.KHMER_CURRY, gap: 1 }}>
          <LogoutIcon />
          Sign out
        </MenuItem>
      </CustomMenu>
    </Stack>
  );
};
