import { LoadingButton } from "@mui/lab";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { CustomDialog, CustomDialogTitle } from "component/CustomDialog";
import { ErrorAlert } from "component/ErrorAlert";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useFetcher, useParams } from "react-router-dom";

interface Props {
  onClose(): void;
  missionLegIdsToDelete: (number | string)[];
}

export const DeleteConfirmationDialog = ({ onClose, missionLegIdsToDelete }: Props) => {
  const params = useParams();
  const fetcher = useFetcher();
  const { handleSubmit } = useForm();

  useEffect(() => {
    if (missionLegIdsToDelete.length === 0) {
      onClose();
    }
  }, [onClose, missionLegIdsToDelete.length]);

  const onSubmit = () => {
    const formData = new FormData();

    formData.append("action", "delete");
    formData.append("legIds", JSON.stringify(missionLegIdsToDelete));

    fetcher.submit(formData, {
      action: `/main/dashboard/mission/edit/${params.missionId}/legs`,
      method: "post",
    });
  };

  return (
    <CustomDialog open={missionLegIdsToDelete.length > 0} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <CustomDialogTitle onClose={onClose} title="Delete mission legs" />
        <DialogContent dividers sx={{ maxWidth: 420 }}>
          <Typography>
            Are you sure you would like to delete {missionLegIdsToDelete.length} mission{" "}
            {missionLegIdsToDelete.length > 1 ? "legs" : "leg"}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ gap: 2 }}>
          {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
          <LoadingButton
            size="large"
            variant="contained"
            type="submit"
            loading={fetcher.state === "loading" || fetcher.state === "submitting"}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </CustomDialog>
  );
};
