import {
  Alert,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { COLORS } from "style";
import * as api from "api";
import { useQuery } from "@tanstack/react-query";
import { LoadingIndicator } from "component/LoadingIndicator";
import { ErrorAlert } from "component/ErrorAlert";
import { useMemo } from "react";
import { transformAssetView } from "utility/transformer";
import { Link, useFetcher, useSearchParams } from "react-router-dom";
import { AssetIdentifier, AssetStatus, ListFilters } from "module/common";
import { AssetView } from "type/model/view";
import { isBetweenTime } from "utility/dateTime";
import { NotSet } from "component/NotSet";
import { pathWithSearchParams } from "utility/router";

const SELECTED_ASSET_KINDS_KEY = "selected-asset-kinds";

export const AssetList = () => {
  const fetcher = useFetcher();
  const assetsQuery = useQuery({
    queryKey: ["assets"],
    queryFn: api.fetchAssets,
  });

  const [searchParam] = useSearchParams();

  const assets = useMemo(() => {
    const selectedAssetKinds = searchParam.get(SELECTED_ASSET_KINDS_KEY)?.split(",") ?? [];

    return (
      assetsQuery.data?.data
        ?.map(transformAssetView)
        .filter((asset) => !selectedAssetKinds.length || selectedAssetKinds.includes(asset.assetKind as string)) ?? []
    );
  }, [assetsQuery.data, searchParam]);

  const renderCurrentCrew = (asset: AssetView) => {
    const now = Date.now();
    const currentShift = asset.shifts.find(
      (shift) => shift.startTime && shift.endTime && isBetweenTime(now, shift.startTime, shift.endTime)
    );
    if (!currentShift) {
      return <NotSet label="No current shift" />;
    }
    return `${currentShift.name} Crew`;
  };

  if (assetsQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (assetsQuery.isError) {
    return <ErrorAlert>Something went wrong! Please try again.</ErrorAlert>;
  }

  return (
    <Stack sx={{ py: 7, alignItems: "center" }}>
      <Stack sx={{ width: "90%", gap: 4 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography variant="h1" color={COLORS.BLACK_PANTHER}>
            Assets
          </Typography>
          <Typography variant="body2">Manage asset configuration and shift settings</Typography>
        </Stack>
        <ListFilters />
        {assets.length > 0 ? (
          <TableContainer
            sx={{
              border: `1px solid ${COLORS.DISCO_BALL}`,
              borderRadius: "4px",
              "& th": {
                borderColor: COLORS.DISCO_BALL,
                fontSize: (theme) => theme.typography.subtitle1,
                color: COLORS.DARK_ELF,
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Asset</TableCell>
                  <TableCell>Current crew</TableCell>
                  <TableCell>Asset status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assets.map((asset) => {
                  return (
                    <TableRow
                      key={asset.id}
                      sx={{
                        verticalAlign: "top",
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& td, & th": {
                          borderColor: COLORS.DISCO_BALL,
                          fontSize: (theme) => theme.typography.body2.fontSize,
                          color: COLORS.DARK_ELF,
                        },
                      }}
                    >
                      <TableCell>
                        <AssetIdentifier asset={asset} />
                      </TableCell>
                      <TableCell>{renderCurrentCrew(asset)}</TableCell>
                      <TableCell>
                        <Stack sx={{ alignItems: "flex-start" }}>
                          <AssetStatus asset={asset} />
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "middle", textAlign: "end" }}>
                        <Button component={Link} to={pathWithSearchParams(`${asset.id}`)} variant="outlined">
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Alert severity="info">There are no assets</Alert>
        )}
        {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
      </Stack>
    </Stack>
  );
};
