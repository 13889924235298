import { LoadingButton } from "@mui/lab";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { CustomDialog, CustomDialogTitle } from "component/CustomDialog";
import { ErrorAlert } from "component/ErrorAlert";
import { useForm } from "react-hook-form";
import { useFetcher, useParams } from "react-router-dom";

interface Props {
  onClose(): void;
}

export const DeleteConfirmationDialog = ({ onClose }: Props) => {
  const params = useParams();
  const fetcher = useFetcher();
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    const formData = new FormData();

    formData.append("action", "delete");

    fetcher.submit(formData, {
      action: `/main/dashboard/mission/edit/${params.missionId}`,
      method: "post",
    });
  };

  return (
    <CustomDialog open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <CustomDialogTitle onClose={onClose} title="Delete mission" />
        <DialogContent dividers sx={{ maxWidth: 420 }}>
          <Typography>Are you sure you would like to delete the mission? This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions sx={{ gap: 2 }}>
          {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
          <LoadingButton
            size="large"
            variant="contained"
            type="submit"
            loading={fetcher.state === "loading" || fetcher.state === "submitting"}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </CustomDialog>
  );
};
