import { App } from "App";
import { NoAccess } from "component/NoAccess";
import { ALL_USER_ROLES } from "constant";
import { AssetDetails, AssetList, assetDetailsAction } from "module/assets";
import {
  Login,
  AuthLayout,
  loginAction,
  ForgotPassword,
  forgotPasswordAction,
  resetPasswordAction,
  ResetPassword,
  Invitation,
  invitationAction,
} from "module/auth";
import { CreateMissionLayout, CreateMissionLayoutBottom } from "module/create-mission";
import { CreateNetsMission } from "module/create-nets-mission";
import { CreateOtherMission, createOtherMissionAction } from "module/create-other-mission";
import { CreatePrimaryMission } from "module/create-primary-mission";
import { CreateSecondaryMission } from "module/create-secondary-mission";
import { DashboardLayout, dashboardLayoutAction } from "module/dashboard";
import { EditMissionLayout, editMissionLayoutAction } from "module/edit-mission";
import { EditMissionDetails, editMissionDetailsAction } from "module/edit-mission-details";
import { EditMissionLegs, editMissionLegAction } from "module/edit-mission-legs";
import { mainAction, MainLayout } from "module/main";
import { MissionLayout } from "module/mission";
import { UserList, userListAction } from "module/users";
import { createBrowserRouter, redirect } from "react-router-dom";
import { UserRoles } from "type/model/view";
import { checkRoleLoader, getCurrentUser, pathWithSearchParams } from "utility/router";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        loader: () => redirect(pathWithSearchParams("/main/dashboard")),
      },
      {
        path: "main",
        id: "main",
        element: <MainLayout />,
        action: mainAction,
        loader: async ({ request }) => {
          const currentUser = await getCurrentUser(request);
          return { currentUser };
        },
        children: [
          {
            index: true,
            loader: () => redirect(pathWithSearchParams("dashboard")),
          },
          {
            path: "dashboard",
            element: <DashboardLayout />,
            action: dashboardLayoutAction,
            loader: checkRoleLoader(ALL_USER_ROLES),
          },
          {
            path: "dashboard/mission",
            element: <MissionLayout />,
            loader: checkRoleLoader(ALL_USER_ROLES),
            children: [
              {
                path: "create",
                loader: checkRoleLoader([UserRoles.Tasker]),
                element: <CreateMissionLayout />,
                children: [
                  {
                    index: true,
                    element: <CreateMissionLayoutBottom />,
                  },
                  {
                    path: "primary",
                    loader: checkRoleLoader([UserRoles.Tasker]),
                    element: <CreatePrimaryMission />,
                  },
                  {
                    path: "secondary",
                    loader: checkRoleLoader([UserRoles.Tasker]),
                    element: <CreateSecondaryMission />,
                  },
                  {
                    path: "nets",
                    loader: checkRoleLoader([UserRoles.Tasker]),
                    element: <CreateNetsMission />,
                  },
                  {
                    path: "other",
                    loader: checkRoleLoader([UserRoles.Tasker]),
                    action: createOtherMissionAction,
                    element: <CreateOtherMission />,
                  },
                ],
              },
              {
                path: "edit/:missionId/*",
                element: <EditMissionLayout />,
                action: editMissionLayoutAction,
                children: [
                  {
                    index: true,
                    loader: () => redirect(pathWithSearchParams("details")),
                  },
                  {
                    path: "details/*",
                    element: <EditMissionDetails />,
                    action: editMissionDetailsAction,
                    loader: checkRoleLoader(ALL_USER_ROLES),
                  },
                  {
                    path: "legs/*",
                    element: <EditMissionLegs />,
                    loader: checkRoleLoader(ALL_USER_ROLES),
                    action: editMissionLegAction,
                  },
                ],
              },
            ],
          },
          {
            path: "no-access",
            element: <NoAccess />,
          },
          {
            path: "assets",
            loader: checkRoleLoader([UserRoles.Manager]),
            children: [
              { index: true, element: <AssetList /> },
              {
                path: ":assetId/*",
                element: <AssetDetails />,
                action: assetDetailsAction,
              },
            ],
          },
          {
            path: "users",
            loader: checkRoleLoader([UserRoles.Manager]),
            action: userListAction,
            element: <UserList />,
          },
        ],
      },
      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            path: "login",
            element: <Login />,
            action: loginAction,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
            action: forgotPasswordAction,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
            action: resetPasswordAction,
          },
          {
            path: "invitation",
            element: <Invitation />,
            action: invitationAction,
          },
        ],
      },
      {
        path: "*",
        loader: () => redirect("/"),
      },
    ],
  },
]);
