import styled from "@emotion/styled";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { ClearIcon } from "./Icons";
import { COLORS } from "style";

export const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    padding: 16,
  },
  "& .MuiDialogActions-root": {
    padding: 16,
  },
}));

export interface DialogTitleProps {
  title?: string;
  onClose: () => void;
}

export const CustomDialogTitle = (props: DialogTitleProps) => {
  const { title, onClose } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, color: COLORS.CYNICAL_BLACK, fontSize: (theme) => theme.typography.h3.fontSize }}>
      {title}
      {onClose ? (
        <IconButton
          size="small"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: COLORS.INDUSTRIAL_REVOLUTION,
          }}
        >
          <ClearIcon width={30} height={30} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
