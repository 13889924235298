import {
  AssetWithRelatedAssetConfigurationEnum,
  AssetWithRelatedAssetKindEnum,
  MissionWithRelatedAviationRiskEnum,
  MissionWithRelatedStatusEnum,
  MissionAssetWithRelatedAssetKindEnum,
  MissionAssetWithRelatedAssetConfigurationEnum,
} from "./api";

export enum AssetViewStatus {
  Online = "online",
  Offline = "offline",
}

export interface Identifiable {
  id: number;
}

export interface ShiftView extends Identifiable {
  assetId: number | null;
  name: string | null;
  startTime: Date | null;
  endTime: Date | null;
  surgeEndTime: Date | null;
  color: string | null;
}

export interface CrewView extends Identifiable {
  firstName: string | null;
  lastName: string | null;
  role: string | null;
  shifts: ShiftView[];
}

export interface AssetUnavailabilityView extends Identifiable {
  startTime: Date | null;
  endTime: Date | null;
  comment: string | null;
}

export interface AssetView extends Identifiable {
  name: string | null;
  assetKind: AssetWithRelatedAssetKindEnum | null;
  comment: string | null;
  callSign: string | null;
  registration: string | null;
  dashboardOrder: number;
  status: AssetViewStatus;
  unavailability: AssetUnavailabilityView | null;
  crew: CrewView[];
  shifts: ShiftView[];
  aircraftId: number | null;
  roadAmbulanceId: number | null;
  homeBaseAddressId: number | null;
  homeBaseAirportId: number | null;
  homeBaseHelipadId: number | null;
  assetConfiguration: AssetWithRelatedAssetConfigurationEnum | null;
}

export interface MissionLegView extends Identifiable {
  enteredStartTime: Date | null;
  enteredEndTime: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  sarTime: Date | null;
  fromText: string | null;
  toText: string | null;
  legType: string | null;
  stage: string | null;
  pob: number | null;
  legTime: number | null;
  legOrder: number;
}

export interface MissionView extends Identifiable {
  createBy: number | null;
  status: MissionWithRelatedStatusEnum | null;
  assetId: number;
  legs: MissionLegView[];
  crew: CrewView[];
  missionAsset: MissionAssetView | null;
  taskingType: string | null;
  patientPriority: string | null;
  assessmentCondition: string | null;
  riskScore: MissionWithRelatedAviationRiskEnum | null;
  mru: string | null;
  comments: string | null;
  estimatedReturnToBaseTime: Date | null;
  isMedicalMissionRespondAvailable: boolean | null;
  plannedDepartureTime: Date | null;
  recallTime: number | null;
}

export interface MissionAssetView extends Identifiable {
  name: string | null;
  assetKind: MissionAssetWithRelatedAssetKindEnum | null;
  comment: string | null;
  callSign: string | null;
  registration: string | null;
  dashboardOrder: number;
  aircraftId: number | null;
  roadAmbulanceId: number | null;
  homeBaseAddressId: number | null;
  homeBaseAirportId: number | null;
  homeBaseHelipadId: number | null;
  assetConfiguration: MissionAssetWithRelatedAssetConfigurationEnum | null;
}

export enum UserRoles {
  Observer = "observer",
  Tasker = "tasker",
  Manager = "manager",
}

export enum UnavailabilityOptions {
  Online = "online",
  OfflinePlanned = "offline-planned",
  OfflineUnplanned = "offline-unplanned",
}

export enum NetsMedicalOptions {
  Sydney = "sydney",
  Newcastle = "newcastle",
  Canberra = "canberra",
}
