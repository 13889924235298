import { Typography } from "@mui/material";
import * as api from "api";
import { FormProvider, useForm } from "react-hook-form";
import { ActionFunction, redirect, useFetcher, useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { SetPasswordFormProps, setPasswordSchema } from "type/schema";
import { ErrorText } from "component/ErrorMessage";
import { AxiosError } from "axios";
import { SetPasswordForm } from "./SetPasswordForm";

export const invitationAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData();

  const errorMessage = "Invalid invitation link!";
  try {
    const password = formData.get("password");
    const invitation_token = formData.get("invitation_token");
    if (typeof invitation_token !== "string" || typeof password !== "string" || !invitation_token || !password) {
      return { formError: errorMessage };
    }

    await api.updateInvitation({ user: { password, invitation_token } });

    const currentUser = await api.fetchCurrentUser();
    if (!(currentUser.data.is_manager || currentUser.data.is_observer || currentUser.data.is_tasker)) {
      return {
        formError: "Password was set successfully but user has no active role! Please contact Admin@aeromedicalaustralia.com.au",
      };
    }

    return redirect("/");
  } catch (error) {
    console.error(error);
    if (error instanceof AxiosError && error.response?.status === 422) {
      return { formError: errorMessage };
    }

    return { formError: "Something went wrong! Please try again" };
  }
};

export const Invitation = () => {
  const [searchParams] = useSearchParams();
  const formProps = useForm<SetPasswordFormProps>({
    resolver: zodResolver(setPasswordSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const fetcher = useFetcher();

  const handleSubmit = ({ password }: SetPasswordFormProps) => {
    const formData = new FormData();
    formData.append("password", password);
    formData.append("invitation_token", searchParams.get("invitation_token") ?? "");

    fetcher.submit(formData, {
      action: `/auth/invitation`,
      method: "post",
    });
  };

  return (
    <FormProvider {...formProps}>
      <SetPasswordForm
        heading={
          <>
            <Typography variant="h1">Welcome to Rapid</Typography>
            <Typography variant="body2">
              Hi There, to setup your Rapid account please create a password. <br />
              <br />
              If you have any questions or need assistance, please contact Admin@aeromedicalaustralia.com.au
            </Typography>
          </>
        }
        onSubmit={handleSubmit}
        errorMessage={fetcher.data?.formError ? <ErrorText>{fetcher.data?.formError}</ErrorText> : null}
        loading={fetcher.state === "loading" || fetcher.state === "submitting"}
      />
    </FormProvider>
  );
};
