import { InputLabel, styled } from "@mui/material";
import { COLORS } from "style";

export const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  color: COLORS.BLACK,
  transform: "none",
  position: "relative",
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.h6.fontWeight,
}));
