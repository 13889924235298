import { Stack, Typography } from "@mui/material";
import { ChevronRightIcon } from "component/Icons";
import { DEFAULT_TIME_ZONE, UNKNOWN } from "constant";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useRouteLoaderData } from "react-router-dom";
import { COLORS } from "style";
import { User } from "type/model/api";
import { MissionLegView } from "type/model/view";

interface Props {
  missionLeg: MissionLegView | null;
  isLegCurrent?: boolean;
  isSARTimeReached?: boolean;
}

export const MissionLeg = ({ missionLeg, isLegCurrent, isSARTimeReached }: Props) => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };

  const startTime = missionLeg?.startTime;
  const endTime = missionLeg?.endTime;

  if (!missionLeg) {
    return (
      <Stack
        direction={"row"}
        sx={{
          p: 1,
          gap: 1,
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: "4px",
          borderColor: COLORS.DISCO_BALL,
          color: COLORS.NOGHREI_SILVER,
          alignItems: "center",
        }}
      >
        <Typography variant="h6">TBC</Typography>
      </Stack>
    );
  }

  let borderColor = COLORS.DISCO_BALL;
  let color = COLORS.NOGHREI_SILVER;
  if (isLegCurrent) {
    borderColor = isSARTimeReached ? COLORS.KHMER_CURRY : COLORS.DARK_ELF;
    color = isSARTimeReached ? COLORS.KHMER_CURRY : COLORS.DARK_ELF;
  }

  return (
    <Stack
      direction={"row"}
      sx={{
        p: 1,
        gap: 1,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: "4px",
        borderColor,
        color,
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{missionLeg.fromText || UNKNOWN}</Typography>
      {startTime ? (
        <Typography variant="body2">
          {format(utcToZonedTime(startTime, currentUser.time_zone || DEFAULT_TIME_ZONE), "HH:mm")}
        </Typography>
      ) : null}

      <ChevronRightIcon color={COLORS.WOLFARM} width={16} height={16} />

      <Typography variant="h6">{missionLeg.toText || UNKNOWN}</Typography>
      {endTime ? (
        <Typography variant="body2">
          {format(utcToZonedTime(endTime, currentUser.time_zone || DEFAULT_TIME_ZONE), "HH:mm")}
        </Typography>
      ) : null}
    </Stack>
  );
};
