import * as api from "api";
import { Aircraft, AssetWithRelatedAssetKindEnum } from "type/model/api";
import { AssetDetailAutocomplete } from "./AssetDetailAutocomplete";
import { Identifiable } from "type/model/view";
import { QueryFunction } from "@tanstack/react-query";

interface Props {
  assetKind: AssetWithRelatedAssetKindEnum | null;
  value: number | null;
}

export const AircraftSelect = ({ assetKind, value }: Props) => {
  const aircraftKind = assetKind === AssetWithRelatedAssetKindEnum.Aeroplane ? "0" : "1";

  const queryKey = ["aircraft", aircraftKind];
  const queryFn = (() => api.fetchAircraft(aircraftKind)) as QueryFunction<{ data: Identifiable[] }>;

  const getLabel = (aircraft: Aircraft) => {
    return `${aircraft.registration} ${aircraft.call_sign}`;
  };

  const getUpdatePayload = (selection: Identifiable) => {
    return { aircraft_id: selection.id };
  };

  return (
    <AssetDetailAutocomplete
      queryKey={queryKey}
      queryFn={queryFn}
      getLabel={getLabel}
      getUpdatePayload={getUpdatePayload}
      value={value}
    />
  );
};
