import { Stack, Typography, TypographyProps } from "@mui/material";
import { COLORS } from "style";
import { MissionWithRelatedStatusEnum } from "type/model/api";
import { MissionLeg } from "./MissionLeg";
import { isAfter } from "date-fns";
import orderBy from "lodash/orderBy";
import findLastIndex from "lodash/findLastIndex";

import { MissionView } from "type/model/view";

interface Props {
  mission: MissionView;
}

const StageLabel = (props: TypographyProps) => {
  return <Typography variant="subtitle1" sx={{ color: COLORS.SHUTTER_BLUE }} {...props} />;
};

const containerStyle = { gap: 1, alignItems: "flex-start" };

export const MissionStage = ({ mission }: Props) => {
  const now = Date.now();

  const missionLegs = orderBy(mission.legs, ["legOrder"]) ?? [];

  const legsCount = missionLegs.length;

  if (mission.status === MissionWithRelatedStatusEnum.Draft) {
    return <Typography variant="body2">Draft</Typography>;
  }

  if (mission.status === MissionWithRelatedStatusEnum.Cancelled) {
    return <Typography variant="body2">Cancelled</Typography>;
  }

  if (mission.status === MissionWithRelatedStatusEnum.Complete) {
    if (legsCount === 0) {
      return null;
    }

    if (legsCount === 1) {
      return (
        <Stack sx={containerStyle}>
          <StageLabel>Last leg</StageLabel>
          <MissionLeg missionLeg={missionLegs[0]} />
        </Stack>
      );
    }

    const firstLeg = missionLegs[0];
    const lastLeg = missionLegs[missionLegs.length - 1];
    return (
      <Stack sx={containerStyle}>
        <Stack sx={containerStyle}>
          <StageLabel>First leg</StageLabel>
          <MissionLeg missionLeg={firstLeg} />
        </Stack>

        <Stack sx={containerStyle}>
          <StageLabel>Last leg</StageLabel>
          <MissionLeg missionLeg={lastLeg} />
        </Stack>
      </Stack>
    );
  }

  if (legsCount === 0) {
    return (
      <Stack sx={containerStyle}>
        <StageLabel>Upcoming first leg</StageLabel>
        <MissionLeg missionLeg={null} />
      </Stack>
    );
  }

  const currentLegIndex = findLastIndex(missionLegs, (leg) => Boolean(leg.enteredStartTime && !leg.enteredEndTime));

  if (currentLegIndex > -1) {
    const currentLeg = missionLegs[currentLegIndex];
    const upcomingLeg = missionLegs[currentLegIndex + 1];
    const isSARTimeReached = Boolean(currentLeg.sarTime && isAfter(now, currentLeg.sarTime));

    return (
      <Stack sx={containerStyle}>
        <Stack sx={containerStyle}>
          <StageLabel>Current leg</StageLabel>
          <Stack>
            <MissionLeg missionLeg={currentLeg} isLegCurrent isSARTimeReached={isSARTimeReached} />
            {isSARTimeReached ? (
              <Typography variant="subtitle1" color={COLORS.KHMER_CURRY}>
                SAR Time has been reached
              </Typography>
            ) : null}
          </Stack>
        </Stack>

        {upcomingLeg ? (
          <Stack sx={containerStyle}>
            <StageLabel>Upcoming leg</StageLabel>
            <MissionLeg missionLeg={upcomingLeg} />
          </Stack>
        ) : null}
      </Stack>
    );
  }

  const upcomingLegIndex = missionLegs.findIndex((leg) => !leg.enteredStartTime);

  if (upcomingLegIndex > -1) {
    const upcomingLeg = missionLegs[upcomingLegIndex];

    return (
      <Stack sx={containerStyle}>
        <StageLabel>Upcoming {upcomingLegIndex === 0 ? "first" : ""} leg</StageLabel>
        <MissionLeg missionLeg={upcomingLeg} />
      </Stack>
    );
  }

  const lastLeg = missionLegs[legsCount - 1];
  return (
    <Stack sx={containerStyle}>
      <StageLabel>Last leg</StageLabel>
      <MissionLeg missionLeg={lastLeg} />
    </Stack>
  );
};
