import { Stack } from "@mui/material";
import helicopter from "asset/images/helicopter.jpg";
import { ReactComponent as LogoSvg } from "asset/svg/logo.svg";
import { Link, Outlet } from "react-router-dom";
import { COLORS } from "style";

export const AuthLayout = () => {
  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Stack
        sx={{
          minHeight: 72,
          px: 4,
          justifyContent: "center",
          borderBottom: `1px solid ${COLORS.CHRISTMAS_SILVER}`,
        }}
      >
        <Stack component={Link} to="/">
          <LogoSvg />
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ flexGrow: 1 }}>
        <Stack sx={{ flexGrow: 1, alignItems: "center", mt: "220px" }}>
          <Stack sx={{ width: 350, m: 2 }}>
            <Outlet />
          </Stack>
        </Stack>
        <Stack
          sx={{
            backgroundImage: `url(${helicopter})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: 650,
          }}
        ></Stack>
      </Stack>
    </Stack>
  );
};
