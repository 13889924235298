import { Stack, Button, Checkbox, Typography } from "@mui/material";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { COLORS } from "style";
import { AssetKindSelect } from "./AssetKindSelect";
import { SELECTED_ASSET_KINDS_KEY, SHOW_COMPLETED_MISSIONS_KEY } from "constant";

interface Props {
  displayShowCompletedMissionsFilter?: boolean;
}

export const ListFilters = ({ displayShowCompletedMissionsFilter }: Props) => {
  const [searchParam, setSearchParams] = useSearchParams();

  const selectedAssetKinds: string[] = useMemo(
    () => searchParam.get(SELECTED_ASSET_KINDS_KEY)?.split(",") ?? [],
    [searchParam]
  );

  const showCompletedMission: boolean = useMemo(
    () => searchParam.get(SHOW_COMPLETED_MISSIONS_KEY) === "true" ?? false,
    [searchParam]
  );

  const handleClearFilters = () => {
    searchParam.delete(SELECTED_ASSET_KINDS_KEY);
    if (displayShowCompletedMissionsFilter) {
      searchParam.delete(SHOW_COMPLETED_MISSIONS_KEY);
    }
    setSearchParams(searchParam);
  };

  const handleAssetKindChange = (newValue: string[]) => {
    if (newValue.length > 0) {
      searchParam.set(SELECTED_ASSET_KINDS_KEY, newValue.join(","));
    } else {
      searchParam.delete(SELECTED_ASSET_KINDS_KEY);
    }
    setSearchParams(searchParam);
  };

  const handleShowCompletedMissionChange = () => {
    if (!showCompletedMission) {
      searchParam.set(SHOW_COMPLETED_MISSIONS_KEY, "true");
    } else {
      searchParam.delete(SHOW_COMPLETED_MISSIONS_KEY);
    }
    setSearchParams(searchParam);
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        bgcolor: COLORS.DOCTOR,
        alignItems: "center",
        height: 50,
        justifyContent: "space-between",
        p: 4,
        borderRadius: "4px",
      }}
    >
      <Stack direction="row" sx={{ gap: 2 }}>
        <AssetKindSelect value={selectedAssetKinds} onChange={handleAssetKindChange} />
        {displayShowCompletedMissionsFilter ? (
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Checkbox
              title="Show completed missions"
              checked={showCompletedMission}
              onChange={handleShowCompletedMissionChange}
            />
            <Typography variant="body2">Show completed missions</Typography>
          </Stack>
        ) : null}
      </Stack>
      <Button variant="text" sx={{ color: COLORS.HAILEY_BLUE, p: 0 }} onClick={handleClearFilters}>
        Clear filters
      </Button>
    </Stack>
  );
};
