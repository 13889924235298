import { Stack, Typography } from "@mui/material";
import { UNKNOWN } from "constant";
import { COLORS } from "style";
import { User } from "type/model/api";
import { formatName } from "utility";
import { transformEnumValue } from "utility/transformer";

interface Props {
  users: User[];
}

export const DashboardUsers = ({ users }: Props) => {
  if (!users?.length) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        bgcolor: COLORS.WHITE,
        borderRadius: "10px",
        border: `1px solid ${COLORS.EXTRAORDINARY_ABUNDANCE_OF_TINGE}`,
        p: 2,
        gap: 7,
        alignItems: "center",
      }}
    >
      {users.map((user, index) => {
        return (
          <Stack key={index} sx={{ gap: "4px" }}>
            <Typography variant="subtitle1" color={COLORS.TROLLEY_GREY}>
              {transformEnumValue(user?.position) || UNKNOWN}
            </Typography>
            <Typography variant="h6">{formatName(user.first_name, user.last_name)}</Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
